// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ZEdit from './zEdit.js';
import Report from './wReport.js';

// Account Layout

var layout = [
	{ type: "section", cellWidth: 3 },
	{ type: "static", field: "name", label: "Client Name", width: 4 },
	{ type: "static", field: "credits", label: "SMS Credits", notes: "1 Credit = 1 SMS", width: 3 },
];

// Report Layout
var reportLayout = {
	saved:   { name: 'Queued', notes: 'Messages received by our API (1 Credit Each)' },
	queued:  { name: 'Injected', notes: 'Messages sent to carrier' },
	failed:  { name: 'Failed', notes: 'Failed delivery' },
	stop:    { name: 'Removes', notes: 'Subscribers removed and reply sent (1 Credit Each)' },
	support: { name: 'Support', notes: 'Support replies sent (1 Credit Each)' },
	reply:   { name: 'Replies', notes: 'Replies received from subscribers' },
};

// The edit (detail) wrapper object

@observer
export default class Dashboard extends React.Component {

		
	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;
  						
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
				
				<div className="zero-detail-control-wrapper">
					<div className="row zero-detail-controls">
						<div className="col-sm-12 zero-detail-text">
							<h4>Account Overview</h4>
						</div>
					</div>
				</div>
				
				<ZEdit store={store.editor} template={layout} user={store.authData} ckedit={uiConfig.ckEdit} />
				
				<Report stats={store.modeData.stats} layout={reportLayout} />
								
			</div>
    );
    
  }
  
}
