// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
var moment = require('moment');

// ### MOBILE LOOKUP TOOL ###

@observer
export default class Lookup extends React.Component {

  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) {
			return (<div className="app-content-wrapper">
				<div className="container-fluid app-pad1">
					<pre>
						<i className="fa fa-refresh fa-spin" aria-hidden="true"></i> Loading Dashboard...
					</pre>
  			</div>
  			</div>
  		);
		}
		
		// generate result
		var summary = '';
		var logs ='';
		var feature;
		var reply = '';
		
		// Result
		if (store.modeData && (store.modeData.status)) {
			feature = <MobileFound store={store} />
			summary = <Result store={store} />
			if (store.modeData.status !== 'Error') logs = <Logs store={store} />
			if (! (store.modeData.statusCode > 1)) reply = <Reply store={store} />
		}
		
		// No result
		else {
			feature = <MobileLookup store={store} />
		}
		
		
		
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
				<Header />
				{feature}
				{summary}
				{reply}
				{logs}
			</div>
    );
    
  }
  
}

// ### HEADER ###
@observer
class Header extends React.Component {

	render() {
		
		var store = this.props.store;
		
		return (
			<div className="row zero-sms-section">
				<div className="col-sm-12 zero-detail-text">
					<h3>Mobile subscriber status and history lookup</h3>
				</div>
			</div>
		);
	}

}


// ### MOBILE LOOKUP ###

@observer
class MobileLookup extends React.Component {

	setPhone = event => {
		this.props.store.lookupField = event.target.value;
	}
	

	// Key bindings
	componentDidMount = event => {
		$(document.body).off('keydown.smsapi-lookup');
		$(document.body).on('keydown.smsapi-lookup', this.handleKeyDown);
	}
	
	componentWillUnMount = event => {
		$(document.body).off('keydown.smsapi-lookup', this.handleKeyDown);
	}
	
	handleKeyDown = event => {
		if (event.keyCode === 13) {
			var active = document.activeElement;
			if (active && (active.id === 'phone-search')) {
				this.clickLookup();
			}
		}
	}

	clickLookup = () => {
	
		var store = this.props.store;
		
		// Parse the number
		var phone = store.lookupField || '';
		phone = phone.replace(/\D/g, '');
		if (phone !== store.lookupField) store.lookupField = phone;
		
		if (! /^\d{10}$/.test(phone)) {
			alert("Please enter a 10-digit US Mobile Number")
			return;
		}
		
		store.nav('lookup', phone);
		
	}
	

	render() {
		
		var store = this.props.store;
		var bLookup = { txt: 'Lookup', mode: '', icon: "fa fa-search-plus", click: this.clickLookup };
		
		return (
			<div className="row zero-sms-section" style={{paddingBottom: '12px'}}>
				<div className="col-sm-3 zero-cell">
					<span className="zero-edit-label"><b>Mobile Number</b></span>
					<input type="text" autoComplete="off" name="phone" id='phone-search' className="form-control" value={store.lookupField} onChange={this.setPhone} />
				</div>
				<div className="col-sm-3 zero-cell">
					<br />
					<Button key='b.lookup' conf={bLookup} />
				</div>
			</div>
		);
	}

}


// ### Lookup complete

@observer
class MobileFound extends React.Component {

	startOver = () => {
		var store = this.props.store;
		store.lookupField = '';
		store.replyField = '';
		store.nav('lookup', '');
	}
	
	render() {
		
		var store = this.props.store;
		var bReset = { txt: 'Start Over', mode: '', icon: "fa fa-undo", click: this.startOver };
		
		return (
			<div className="row zero-sms-section">
				<div className="col-sm-3 zero-cell">
					<span className="zero-edit-label"><b>Mobile Number</b></span>
					<p className="form-control-static zero-sms-info">{fphone(store.modeData.phone)}</p>
				</div>
				<div className="col-sm-3 zero-cell">
					<br />
					<Button key='b.lookup' conf={bReset} />
				</div>
			</div>
		);
	}

}


// ### RESULT ###

@observer
class Result extends React.Component {

	opt(phone, status) {
		this.props.store.doOptOut(phone, status);	
	}

	render() {
		
		var store = this.props.store;
		
		if (! (store.modeData && store.modeData.status)) return false; 
		var data = store.modeData;

		var bOptin     = { txt: 'Opt-In', mode: 'btn-primary', icon: "fa fa-plus-circle", click: this.opt.bind(this, store.type, 1) };
		var bRemove    = { txt: 'Remove', mode: 'btn-primary', icon: "fa fa-ban", click: this.opt.bind(this, store.type, 2) };
		var bBlacklist = { txt: 'Blacklist', mode: 'btn-danger', icon: "fa fa-ban", click: this.opt.bind(this, store.type, 3) };
		
		var buttons = [];
		if (data.statusCode < 2) buttons.push(<Button key='remove' conf={bRemove} />);
		if (data.statusCode === 2) buttons.push(<Button key='optin' conf={bOptin} />);
		if (data.statusCode !== 3) buttons.push(<Button key='blackList' conf={bBlacklist} />);
		
		var statusMsg = store.modeData.status;
		if (store.modeData.details) statusMsg += ': ' + store.modeData.details;
		
		return (
			<div className="row zero-sms-section">
			
				<div className="col-sm-4 zero-cell">
					<span className="zero-edit-label"><b>Lookup Result</b></span>
					<p className="form-control-static zero-sms-info">{statusMsg}</p>
				</div>
				
				<div className="col-sm-8 zero-cell">
					<br />
					{buttons}
				</div>
			
			</div>
		);
	}

}


// ### REPLY

@observer
class Reply extends React.Component {

	setReply = event => {
		this.props.store.replyField = event.target.value;
	}
	
	sendSms = () => {
	
		var store = this.props.store;
		
		// Parse the number
		
		var phone = store.modeData.phone || '';
		phone = phone.replace(/[^\d]/g, '');
		
		if (! /^\d{10}$/.test(phone)) {
			alert("Error: Invalid phone number");
			return;
		}
		
		// Parse the text
		
		var text = store.replyField || '';
		text = text.trim();
			
		if (! text) {
			alert("Error: Please enter the text you wish to send");
			return;
		}
		
		if (text.length > 160) {
			alert('Message text must be 160 characters or shorter');
			return;
		}
		
		this.props.store.doSendReply(phone, text);

	}
	
	render() {
		
		var store = this.props.store;
		var bReset = { txt: 'Start Over', mode: '', icon: "fa fa-undo", click: this.startOver };
		
		var size = 0;
		if (store.replyField) size = store.replyField.length;
		var left = 160 - size;
		var count = `${size} Chars / ${left} Remaining`;
				
		return (

				<div className="row zero-row zero-sms-section" style={{paddingBottom: '12px'}}>
					<div className="col-sm-7 zero-cell">
						<span className="zero-edit-label"><b>Send a message to this number:</b></span>
						<div className="zero-sms">
							<textarea className="form-control" autoComplete="off" id='phone-reply' value={store.replyField} onChange={this.setReply}></textarea>
							<div className="zero-green">{count}</div>
						</div>
						
						<button onClick={this.sendSms} className="btn btn-default zero-detail-btn app-button-affordance" style={{float: 'right'}}>Send Text Message &nbsp;<i className="fa fa-comment-o"></i></button>
												
					</div>
				</div>

		);
	}

}

// ### GENERIC BUTTON ###

@observer
class Button extends React.Component {

	render() {
		var b = this.props.conf;
		return (
			<button className={"btn btn-default zero-detail-btn app-button-affordance " + b.mode} onClick={b.click}>
				<span>{b.txt} </span>
				<i className={b.icon}></i>
			</button>
		);
	}

}


// ### LOGS OF PAST MESSAGES ###

@observer
class Logs extends React.Component {

	render() {
		
		var store = this.props.store;
	
		var rows = [];
		if (store.modeData && store.modeData.results) {
			store.modeData.results.forEach(doc => {
				rows.push(<Row key={doc._id} data={doc} />);
			});
		}
		
		var searchMode;
		if ( store.modeData.client === 'admin') searchMode = 'All Accounts';
		else searchMode = store.modeData.client;
		
		if (! (rows.length > 0)) {
			return (
    		<div className="row zero-row zero-sms-section">
					<div className="col-md-4 zero-cell">
						<b>Logs:</b>
						<pre className='app-ui-mt5 app-ui-red'>No logs for {store.modeData.phone} in {searchMode}</pre>
					</div>
				</div>
    	)
    }
		
		return (
			<div className="row zero-row zero-sms-section">
				<div className="col-md-12 zero-cell">
					<table className='table table-striped table-hover table-condensed'>
						<tbody>
						<tr>
							<th>Time</th>
							<th>Sender</th>
							<th>Subscriber</th>
							<th></th>
							<th>Type</th>
							<th>Details</th>
						</tr>
						{rows}
						</tbody>
					</table>
				</div>
			</div>
		);
    
	}

}

// ### ROW ###

var labels = {
	r: 'Incoming SMS',
	d: 'Delivery Notify',
	s: 'Sent SMS',
	e: 'Delivery Error',
};

@observer
class Row extends React.Component {

	render() {
		
		var data = this.props.data;
		
		var icon = '';
		var style = '';
		var label = labels[data.t];
		
		if (data.t === 's') {
			icon = (
				<span style={{fontSize: 15}}>
				<i className="fa fa-commenting-o"></i>&nbsp;
				</span>
			)
		}
		else if (data.t === 'r') {

			icon = (
				<span style={{fontSize: 15}}>
				<i className="fa fa-comments-o"></i>&nbsp;
				</span>
			);
			
			if (data.r === 'standard') {
				label = 'STOP Request';
				icon = (
					<span style={{fontSize: 15}}>
					<i className="fa fa-ban"></i>&nbsp;
					</span>
				);			
			}
			
			else if (data.r === 'smart') {
				label = 'Smart Stop';
				icon = (
					<span style={{fontSize: 15}}>
					<i className="fa fa-ban"></i>&nbsp;
					</span>
				);			
			}

			else if (data.r === 'support') {
				label = 'Support';		
			}

		}
				
    return (
    	<tr>
    		<td><nobr>{moment(data.created).format("YYYY-MM-DD HH:mm:ss")}</nobr></td>
    		<td><nobr>{data.c}</nobr></td>
    		<td style={{fontFamily: 'Courier'}}><nobr>{fphone(data.p)}</nobr></td>
    		<td>{icon}</td>
    		<td><nobr>{label}</nobr></td>
    		<td className={style} width='50%'>{data.m || data.s}</td>
    	</tr>
    );
    
	}

}


// Format phone

function fphone(num) {
	
	if (! num) return "";
	
	num = num.toString();
	num = num.replace("+1", "");
	num = num.replace(/^1/, "");
	var match = num.match(/(\d\d\d)(\d\d\d)(\d\d\d\d)/);
	if (match) return `${match[1]}-${match[2]}-${match[3]}`;
	return num;

}
