// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Report2 from './wReport2.js';
import CsvReport from './wCsvReport.js';

const months = ['???', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Billing report wrapper object

@observer
export default class InjectionStats extends React.Component {

	clickHeader = (value) => {
		const store = this.props.store;
		var conf = this.props.conf;
		store.type = value;
		store.doBiz('injectionStats', { reportMode: value });
	}

// ### RENDER ###
	
render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;
  	
  	var reportLayout = {
		total_head: { name: "Totals", type: 'subhead', format: 'info' },
  		total_injected:  { name: 'Injected',  type: 'head', format: '' },
  		total_replies: { name: 'Replies', type: 'head', format: '' },
  		total_errors:  { name: 'Errors',  type: 'head', format: '' },
  	};
  	
  	for (var client of store.modeData.clientList) {
  		reportLayout[client] = { name: client, type: 'subhead', format: 'info' };
		reportLayout[client + '_head'] = { name: '', type: 'headers' };
  		reportLayout[client + '_injected'] = { name: `Injected` };
  		reportLayout[client + '_replies'] = { name: `Replies` };
  		reportLayout[client + '_errors'] = { name: `Failed` };
  	};
  	
  	var range = {};
  	store.modeData.range.forEach(stamp => {
  		range[stamp] = stamp;
  	});

	let backClick = this.clickHeader.bind(this, '');
	let backBtn = <a className="btn btn-sm btn-primary" onClick={backClick}>Back</a>;
  
	let spinner = '';
	if (store.bizLoading) {
		spinner = <i className="fa fa-spinner" aria-hidden="true"></i>;
		backBtn = '';
	}

	let reportType = '';
	let click = this.clickHeader;
	if (store.type) {
		reportType = `- ${store.type}`;
		console.log(store.type);
		// click = null; 
	}
	else {
		backBtn = '';
	}

	let download;
	if (! spinner) download = <CsvReport store={store} stats={store.modeData.stats} layout={reportLayout} range={range} name='injectionStats' />
	
	// RENDER EVERYTHING

    return (
		<div>

			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-12 zero-detail-text">
						<h4>Injection Stats {reportType} &nbsp;{backBtn} &nbsp; {download} {spinner}</h4>
					</div>
				</div>
			</div>
			
			<Report2 store={store} stats={store.modeData.stats} layout={reportLayout} range={range} headerClick={click} />

		</div>
    );
    
  }
  
}
