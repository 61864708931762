// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Layout

var layout = {

	AdminServer:      { name: 'Admin UI' },
	ApiServer:        { name: 'API Service' },
	MessageInjector:  { name: 'Message Injector' },
	PostbackReceiver: { name: 'Postback Receiver' },
	ReplyForwarder:   { name: 'Reply Forwarder' },
	
};

// The edit (detail) wrapper object

@observer
export default class System extends React.Component {

	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	if (! this.props.store.modeData) return false;
  	
  	var processInfo = this.props.store.modeData.procs;
  	var queueSize = this.props.store.modeData.queue;
  	
  	var entries = [];
 
   	for (var p in layout) {
  	  		
  		var statusColor = 'app-red';
  		var status = '';
  		var msg = "";
  		var age = '';
  		
  		var stats;
  		if (processInfo[p]) stats = processInfo[p];
  		else stats = { };
  		
  		if (! stats.status) {
  			status = "Error";
  			msg = "No pm2 entry";
  		}
  		else if (stats.status === 'online') {
  			statusColor = 'app-green';
  			status = 'Up';
  			if (stats.message) msg = stats.message;
  			if (stats.age) age = stats.age + " sec"
  		}
  		else {
  			status = stats.status;
  		}
  		
  		var extra = [];
  		if (stats.extra) {
  			for (var x in stats.extra) {
  				extra.push(
						<div className="row" key={x}>
							<div className="col-xs-8">{x}</div>
							<div className="col-xs-4">{stats.extra[x]}</div>
						</div>
					);
  			}
  		}
  		
  		var conf = {
  			name: layout[p].name,
  			status: status,
  			color: statusColor,
  			msg: msg,
  			date: age,
  			extra: extra,
  		};
  		
			entries.push(<Entry data={conf} key={p} />);
  	
  	}
  	
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
			
	

				<div className="app-system">

					<div className="row app-system-row" key='queue'>
						<div className="col-sm-12 app-nobr"><h4>Outbound Queue Size: {queueSize}</h4></div>
					</div>

					<div className="row app-system-row" key='monitor'>
						<div className="col-sm-12 app-nobr"><h4>Service Status Information</h4></div>
					</div>

					<div className="row app-system-row" key='header'>
						<div className="col-sm-3 app-nobr"><b>Service</b></div>
						<div className="col-sm-3 app-nobr"><b>Status</b></div>
						<div className="col-sm-2 app-center app-nobr"><b>Last Checkin</b></div>
						<div className="col-sm-4 app-nobr"><b>Extra Info</b></div>
					</div>
				
					{entries}
				</div>
				
			</div>
    );
    
  }
  
}

@observer
class Entry extends React.Component {

	render() {
		
		var data = this.props.data;
		
		return (
		<div className="row app-system-row">
			<div className="col-sm-3 app-nobr"><b>{data.name}</b></div>
			<div className="col-sm-3 app-nobr"><span className={data.color}><b>{data.status}</b></span>&nbsp;{data.msg}</div>
			<div className="col-sm-2 app-center app-nobr">{data.date}</div>
			<div className="col-sm-4 app-nobr">{data.extra}</div>
		</div>
		);
	}

}
