import ReactDOM from 'react-dom';
import React, { Component } from 'react';

import App from './components/App.js';
import Invalid from './components/Invalid.js';

// Application state
var AppStore = require('./appStore.js');
var myAppState = new AppStore();

// Routing
var myRoute = require('./appRouting.js');
myRoute(myAppState);

ReactDOM.render(
  <App store={myAppState} />,
  document.getElementById('app')
);
