// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

var moment = require('moment');
var num = require('numeral'); // http://numeraljs.com/
num.zeroFormat('');

// Makes a report

export default class Report2 extends React.Component {

  render() {
  
  	// Use the app state (store)
  	const stats = this.props.stats;
  	const layout = this.props.layout;
  	const range = this.props.range;

	const store = this.props.store;
  	
  	// Column Headers
  	var count = 0;
  	var headers = [];
  	headers.push(<th key="blank"></th>);
  	
  	var rangeArray = [];
  	for (var col in range) {
		if (this.props.headerClick) {
			let myClick = this.props.headerClick.bind(this, range[col]);
			headers.push(<th className='app-report-special' key={col}><nobr><a onClick={myClick}><u>{range[col]}</u></a></nobr></th>);
		}
		else {
  			headers.push(<th className='app-report-special' key={col}><nobr>{range[col]}</nobr></th>);
		}
  		rangeArray.push(col);
  	}
	
	// Rows
	var rows = [];
	for (var r in layout) {
		rows.push(<Row
			key={r}
			store={store}
			layout={layout[r]}
			range={rangeArray}
			data={stats[r] || {}}
			headers={headers}
		/>);
	}
		
    return (
			<div className="app-report-container">
		
				<table className='table table-hover table-condensed table-bordered app-report-dash'>
					<tbody>
					<tr>{headers}</tr>
					{rows}
					</tbody>
				</table>
			
			</div>
    );
  }
  
}

// ### ROW ###
class Row extends React.Component {

	render() {
				
		var layout = this.props.layout;
		var data = this.props.data;
		var range = this.props.range;

		const store = this.props.store;
		
		var columns = [];
		
		if (layout.type === 'subhead') {
			columns.push(<th key='name' colSpan={range.length + 1}><nobr>{layout.name}</nobr></th>);
		}
		else if (layout.type === 'headers') {
			return <tr>{this.props.headers}</tr>
		}
		else {
			if (layout.type === 'head') columns.push(<th key='name'><nobr>{layout.name}</nobr></th>);
			else columns.push(<td key='name'>{layout.name}</td>);
			
			range.forEach(col => {
				columns.push(<td key={col}>{num(data[col]).format('0,0')}</td>);
			});
		}

		
		
    return (
    	<tr className={layout.format || ''}>
    		{columns}
    	</tr>
    );
    
	}

}
