// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ZEdit from './zEdit.js';

// The edit (detail) wrapper object

@observer
export default class Edit extends React.Component {

	saveRecord() {
		this.props.store.saveEditor();
	}
	
	goBack() {
		window.history.back();
	}

	collapseAll() {
		this.props.store.editor.sections.clear();
	}

	expandAll() {
		var store = this.props.store;
		var uiConfig = this.props.config;
		var editTemplate = uiConfig.editViews[store.type];
		editTemplate.forEach(e => {
			if ((e.type === 'section') && e.name) store.editor.sections.set(e.name, true);
		});
	}
	
	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;

		// PARSE EDIT TEMPLATE
		
		var editTemplate = uiConfig.editViews[store.type];
		if (! editTemplate) { console.log("Error: Missing template %s", store.type); }
		
		// Pre-merge extra stuff into the template. Each element is an object.
		// Build a new clone of it, but with functions getting called and expanded
		// Also check for named sections and add a collapse/expand buttons
	
		var myTemplate = [];
		var hasCollapse = false;
	
		editTemplate.forEach(e => {
			var myElement = {};
			
			if ((e.type === 'section') && e.name) hasCollapse = true;
			
			// Handle custom UI widgets
			if (e.type === 'custom') {
				if (! e.widget) throw new Error('Custom element requires a widget in ' + JSON.stringify(e));
				// Merge and expand functions in parameters, Copy over regular parameters						
				for (var key in e) {
					if (key == 'widget') continue;
					if (typeof(e[key]) === 'function') myElement[key] = e[key](store.modeData.primary, store.modeData);
					else myElement[key] = e[key];
				};
				var Widget = e.widget; // The widget has to be a React class
				myElement.widget = <Widget params={e} doc={self.state.doc} data={self.state.data} />;
			}
		
			// Handle everything else
			else for (var key in e) {
				// Merge and expand functions in parameters, Copy over regular parameters						
				if (typeof(e[key]) === 'function') myElement[key] = e[key](store.modeData.primary, store.modeData);
				else myElement[key] = e[key];
			}
			
			myTemplate.push(myElement);
		
		});

		// GENERATE CONTROLS
		
		var btnz = {
			save: { txt: 'Save', mode: '', icon: "fa fa-floppy-o", click: this.saveRecord.bind(this) },
			back: { txt: 'Back', mode: '', icon: "fa fa-arrow-circle-left", click: this.goBack.bind(this) },
		};

		var buttons = [];
		buttons.push(<Button key='b.back' conf={btnz.back} />);
		
		// Custom Buttons
	
		var customB = '';
		if (uiConfig.editControls) var editCustom = uiConfig.editControls[store.type];
		if (editCustom) {
			customB = editCustom(store);
			buttons.push(customB);
		}

		buttons.push(<Button key='b.save' conf={btnz.save} />);
		
		if (hasCollapse) {
			btnz.show = { txt: 'Show All', mode: 'zero-expand-button', icon: "fa fa-plus-circle", click: this.expandAll.bind(this) };
			btnz.hide = { txt: 'Hide All', mode: 'zero-expand-button', icon: "fa fa-minus-circle", click: this.collapseAll.bind(this) };
			buttons.unshift(<Button key='b.hide' conf={btnz.hide} />);
			buttons.unshift(<Button key='b.show'conf={btnz.show} />);
		}
	
		// Compose the controls
		
		var note = store.type.substr(0,1).toUpperCase() + store.type.substr(1);

		if (store.modeData.created) {
			var date = strftimePDT('%b %e @ %l:%M %p', new Date(store.modeData.created));
			note += " - Created " + date;
		}

		var controls = (
			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-6 zero-detail-text">
						{note}
					</div>
					<div className="col-sm-6 zero-detail-buttons">
						{buttons}
					</div>
				</div>
			</div>		
		);
			
		// RENDER EVERYTHING
				
    return (
			<div className="container-fluid">
				{controls}
				<ZEdit store={store.editor} template={myTemplate} user={this.props.store.authData} ckedit={uiConfig.ckEdit} />
			</div>
    );
  }
  
}

@observer
class Button extends React.Component {

	render() {
		var b = this.props.conf;
		return (
			<button className={"btn btn-default zero-detail-btn app-button-affordance " + b.mode} onClick={b.click}>
				<i className={b.icon}></i>
				<span> {b.txt}</span>
			</button>
		);
	}

}