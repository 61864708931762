// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ZEdit from './zEdit.js';
import Report2 from './wReport2.js';

var months = ['???', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Billing report wrapper object

@observer
export default class ReportBilling extends React.Component {

		
	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;
  	
  	
  	var reportLayout = {
  		total_attempted: { name: 'Total Delivered',  type: 'head', format: 'app-rpt-primary' },
  		total_queued:  { name: 'Total Queued',  type: 'head', format: '' },
  		total_support: { name: 'Total Support', type: 'head', format: '' },
  		total_failed:  { name: 'Total Failed',  type: 'head', format: '' },
  		total_delivered:  { name: 'Net Delivered',  type: 'head', format: '' },
  	};
  	
  	for (var client in store.modeData.clientList) {
  		reportLayout[client] = { name: store.modeData.clientList[client], type: 'subhead', format: 'info' }
  		reportLayout[client + '_queued'] = { name: 'Queued' }
  		reportLayout[client + '_support'] = { name: 'Support' }
  		reportLayout[client + '_failed'] = { name: 'Failed' }
  	};
  	
  	var range = {};
  	store.modeData.range.forEach(stamp => {
  		var y = stamp.substr(0,2);
  		var m = parseInt(stamp.substr(3,2));
  		var m2 = m + 1;
  		if (m2 > 12) m2 = 1; 
  		range[stamp] = (
  			<div>
  				{months[m] + ' 20' + y}<br />
  				{months[m] + ' 13 - ' + months[m2] + ' 12'}
  			</div>
  		)
  	});
  		
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
				
				<div className="zero-detail-control-wrapper">
					<div className="row zero-detail-controls">
						<div className="col-sm-12 zero-detail-text">
							<h4>CDYNE Billing Report</h4>
						</div>
					</div>
				</div>
								
				<Report2 stats={store.modeData.stats} layout={reportLayout} range={range} />
				
			</div>
    );
    
  }
  
}
