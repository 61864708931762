// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import * as mobx from 'mobx';

import ZEdit from './zEdit.js';

// SMS Layout

var layout = [
	{ type: "section" },
	{ type: "text", field: "phone", label: "Mobile Number", width: 3 },
	{ type: "break" },
	{ type: "textsms", field: "text", label: "Message", width: 5 },
];

// The edit (detail) wrapper object

@observer
export default class Test extends React.Component {

	clickTest = () => {
	
		var store = this.props.store;
		var myDoc = mobx.toJS(store.editor.data);
					
		// Parse the number
		
		var phone = String(myDoc.phone) || '';		
		phone = phone.replace(/[^\d]/g, '');
				
		store.editor.data.set('phone', phone);
		
		if (! /^\d{10}$/.test(phone)) {
			store.modeData = {
				status: 'Error',
				details: 'Please enter a 10-digit US Mobile Number',
			}
			return;
		}
		
		// Parse the text
		
		var text = myDoc.text || '';
		
		text = text.replace(/^\s+/, '');
		text = text.replace(/\s+$/, '');
		
		store.editor.data.set('text', text);
		
		if (! text) {
			store.modeData = {
				status: 'Error',
				details: 'Message text cannot be empty',
			}
			return;
		}
		
		if (text.length > 160) {
			store.modeData = {
				status: 'Error',
				details: 'Message text must be 160 characters or shorter',
			}
			return;
		}
		
		this.props.store.doSmsTest();
	}
		
	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;

		// GENERATE CONTROLS
		var test = { txt: 'Send test message', mode: '', icon: "fa fa-comment-o", click: this.clickTest };
					
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
				
				<Header />
				
				<ZEdit store={store.editor} template={layout} user={this.props.store.authData} ckedit={uiConfig.ckEdit} />

				<div className="zero-detail-control-wrapper">
					<div className="row zero-detail-controls">
						<div className="col-sm-12 zero-detail-text">
							<Button key='b.back' conf={test} />
						</div>
					</div>
				</div>
				
				<Result store={store} />
				
			</div>
    );
    
  }
  
}

// ### HEADER ###

@observer
class Header extends React.Component {

	render() {
		
		var store = this.props.store;
		
		return (
			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-12 zero-detail-text">
						<h4>Enter a US Mobile Number and a message to send a test message.</h4>
					</div>
				</div>
			</div>
		);
	}

}

// ### RESULT ###

@observer
class Result extends React.Component {

	render() {
		
		var store = this.props.store;
		
		if (! store.modeData) return false;
		
		return (
			<div className="zero-detail-control-wrapper">
				<div className="col-md-12 zero-col1">
					<b>{store.modeData.status}:</b>
					<pre className='app-ui-mt5 app-ui-red'>{store.modeData.details}</pre>
				</div>
			</div>
		);
	}

}

// ### BUTTON ###

@observer
class Button extends React.Component {

	render() {
		var b = this.props.conf;
		return (
			<button className={"btn btn-default zero-detail-btn app-button-affordance " + b.mode} onClick={b.click}>
				<span>{b.txt} </span>
				<i className={b.icon}></i>
			</button>
		);
	}

}
