// An handy wrapper for simplifying jQuery calls
// =============================================

exports.doHttp = function doHttp(url, method, data, okFunc, errFunc) {

	if (! url) { return exports.handleError(new Error('Missing URL')); }
	if (! method) { return exports.handleError(new Error('Missing method (GET or POST)')); }
	
	// Make an error to get the stack trace
	var oldErr = new Error('in httpGetJson');
	var oldStack = oldErr.stack;
	
	$.ajax({
		url: url,
		type: method,
		data: data,
		
		error: function(r, status, err) {
			exports.handleError(new Error('Connection failed to url ' + url), oldStack); // include the old stack for good logging
			if (errFunc) { errFunc(r, status, err); } // If we got one, call back some extra error handler
		},
		
		success: function(response) {
			okFunc(response);
		},
		
	});

}

// Error Message
// Expects an error object, but can also handle a string

exports.handleError = function handleError(err, extra) {

	var msg;
	
	if (! err) { return; }
	if (err.message) { msg = err.message; }
	else { msg = err; }
	
	if (err.stack) { console.log(err.stack); }
	else {
	console.log("*** Server reported an error: ");
	console.log(msg);
	}
	
	if (extra) { console.log(extra); }
	
	alert(msg);

}