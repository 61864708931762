// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
var moment = require('moment');

// The list object

@observer
export default class Logs extends React.Component {

  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	if (store.loading) return false;
  	if (! store.modeData) return false;
 		var uiConfig = this.props.config;
 		var data = store.modeData;
 		
		// Rows
		var rows = [];
		if (data) {
			data.forEach(doc => {
				rows.push(<Row key={doc._id} data={doc} store={store} />);
			});
		}
		
    return (
			<div className="container-fluid">
		
				<Header />

				<table className='table table-striped table-hover table-condensed'>
					<tbody>
					<tr>
						<th>Message ID</th>
						<th>Time</th>
						<th>Subscriber</th>
						<th></th>
						<th>Type</th>
						<th>Details</th>
					</tr>
					{rows}
					</tbody>
				</table>
			
			</div>
    );
  }
  
}


// ### HEADER ###

@observer
class Header extends React.Component {

	render() {
		
		var store = this.props.store;
		
		return (
			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-12 zero-detail-text">
						<h4>Recent SMS Activity on your account</h4>
					</div>
				</div>
			</div>
		);
	}

}

// ### ROW ###

var labels = {
	r: 'Reply',
	d: 'Delivered',
	s: 'Injected',
	e: 'Error',
};

@observer
class Row extends React.Component {

	doLookup = () => {
		
		var store = this.props.store;
		store.nav('lookup', this.props.data.p);
	
	}

	render() {
		
		var data = this.props.data;
		
		var icon = '';
		var style = '';
		var label = labels[data.t];
		
		if (data.s) label = `${label} ${data.s}`;
		
		if (data.t === 's') {
			icon = (
				<span style={{fontSize: 15}}>
				<i className="fa fa-commenting-o"></i>&nbsp;
				</span>
			)
		}
		else if (data.t === 'r') {

			icon = (
				<span style={{fontSize: 15}}>
				<i className="fa fa-comments-o"></i>&nbsp;
				</span>
			);
			
			if (data.r === 'standard') {
				label = 'STOP Request';
				icon = (
					<span style={{fontSize: 15}}>
					<i className="fa fa-ban"></i>&nbsp;
					</span>
				);			
			}
			
			else if (data.r === 'smart') {
				label = 'Smart Stop';
				icon = (
					<span style={{fontSize: 15}}>
					<i className="fa fa-ban"></i>&nbsp;
					</span>
				);			
			}

			else if (data.r === 'support') {
				label = 'Support';		
			}

		}
				
    return (
    	<tr>
    		<td style={{fontFamily: 'Courier'}}><nobr>{data.k}</nobr></td>
    		<td><nobr>{moment(data.created).format("YYYY-MM-DD HH:mm:ss")}</nobr></td>
    		<td style={{fontFamily: 'Courier'}}><nobr><a onClick={this.doLookup}>{data.p}</a></nobr></td>
    		<td>{icon}</td>
    		<td><nobr>{label}</nobr></td>
    		<td className={style}>{data.m || data.s}</td>
    	</tr>
    );
    
	}

}
