// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

var moment = require('moment');
var num = require('numeral'); // http://numeraljs.com/
num.zeroFormat('');

var specialDays = { all: 'All Time', d30: '30 Days', d7: '7 Days', d0: 'Today' };

// The list object

export default class Report extends React.Component {

  render() {
  
  	// Use the app state (store)
  	var stats = this.props.stats;
  	var layout = this.props.layout;
  	var maxDays = this.props.maxDays || 7;
  	
  	// Column Headers
  	var count = 0;
  	var headers = [];
  	headers.push(<th key="blank"></th>);
  	
 		stats.range.forEach(d => {
 			if (! specialDays[d]) count += 1;
 			if (count <= maxDays) headers.push(<Header key={d} day={d} date={stats.dates[d]} />);
 		});
 		
 		// Rows
 		var rows = [];
 		for (var r in layout) {
 			rows.push(<Row
 				key={r}
 				layout={layout[r]}
 				range={stats.range}
 				maxDays={maxDays}
 				data={stats.report[r] || {}}
 			/>);
 		}
		
    return (
			<div className="app-report-container">
		
				<table className='table table-striped table-hover table-condensed table-bordered app-report-dash'>
					<tbody>
					<tr>{headers}</tr>
					{rows}
					</tbody>
				</table>
			
			</div>
    );
  }
  
}


// ### HEADER ###
class Header extends React.Component {

	render() {
		
		var day = this.props.day;
		var date = this.props.date;
	
		// Special days 
		var special = specialDays[day] || '';
		if (special) {
			return (
				<th className='app-report-special'>
					<br />
					{special}
				</th>
			);
		}
		
		// Normal days
		
		var dayM = moment(date);
		return (
			<th className='app-report-special'>
				{dayM.format("ddd")}
				<br />
				{dayM.format("MMM D")}
			</th>
		);
		
	}

}

// ### ROW ###
class Row extends React.Component {

	render() {
				
		var layout = this.props.layout;
		var data = this.props.data;
		var range = this.props.range;
		var maxDays = this.props.maxDays;
		
		var count = 0;
		var columns = [];
		columns.push(<td key='name'>{layout.name}</td>);
		
		
		range.forEach(d => {
			if (! specialDays[d]) count += 1;
 			if (count <= maxDays) columns.push(<td key={d}>{num(data[d]).format('0,0')}</td>);
 		});
		
		
    return (
    	<tr>
    		{columns}
    	</tr>
    );
    
	}

}
