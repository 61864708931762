"use strict";

// Npm modules
import { createHistory } from 'history';
import { Router } from 'director';

// App modules
var uiConfig = require('./appUiConfig.js');

// SIMPLE ROUTER that navigates in the STORE if the URL is changed

module.exports = function startRouter(store) {

	var routerConfig = {};
	routerConfig[`${uiConfig.appRoot}:a/:b/:c/:d`] = (a, b, c, d) => store.navClient(a, b, c, d);
	routerConfig[`${uiConfig.appRoot}:a/:b/:c`] = (a, b, c) => store.navClient(a, b, c);
	routerConfig[`${uiConfig.appRoot}:a/:b`] = (a, b) => store.navClient(a, b);

	var router = new Router(routerConfig)
	.configure({
		notfound: () => {
			alert("Invalid path!");
			store.navHome();
		},
		html5history: true
	})
	.init();
}