// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ZEdit from './zEdit.js';

// SMS Layout

var layout = [
	{ type: "section", cellWidth: 3 },
	{ type: "static", field: "code", label: "Your Client ID", width: 5 },
	{ type: "break" },
	{ type: "static", field: "apiKey", label: "Your Api Key", notes: "Keep this secret!", width: 3 },
];

var sample = {
	"status":"queued",
	"msgId":"eDcDD4ef-35ae-4304-907d-648eeCF4c889",
	"append":{ "phone":"5555853274","mobile":true,"state":"CA","city":"Malibu"}
}
var sample2 = {"status":"error","details":"Error: Field 'text' is required"};
var sample3 = {"status":"rejected","details":"Result: Not a valid mobile number\nReason: Area Code"}

// The edit (detail) wrapper object

@observer
export default class Api extends React.Component {

		
	// ### RENDER ###
	
  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	var uiConfig = this.props.config;
  	if (store.loading) return false;
  	if (! store.editor) return false;

		// GENERATE CONTROLS
		var test = { txt: 'Send test message', mode: '', icon: "fa fa-comment-o", click: this.doTest };
					
		// RENDER EVERYTHING
    return (
			<div className="container-fluid">
				
				<div className="zero-detail-control-wrapper">
					<div className="row zero-detail-controls">
						<div className="col-sm-12 zero-detail-text">
							<h4>Api Usage Instructions</h4>
						</div>
					</div>
				</div>
				
				<ZEdit store={store.editor} template={layout} user={this.props.store.authData} ckedit={uiConfig.ckEdit} />
				
				<div className="app-docs">
					<br />
					<p>To initiate an SMS, you may POST to or GET this url:</p>
					<pre style={{color: '#008'}}>http://mobiletechapi.com/api</pre>
					<p>You may send data as a form POST, or encoded in the query string of a GET. We recommend a form post, but either one will work. Every request requires the following fields:</p>
					<p>
						<b>client</b> - your client id<br />
						<b>key</b> - your api key<br />
						<b>phone</b> - The 10-digit US phone you are sending to<br />
						<b>text</b> - Up to 160 characters of text
					</p>
					
					<p>Additionally, you may use the following fields to provide opt-in information:</p>
					<p>
						<b>ip</b> - Customer opt-in IP address<br />
						<b>url</b> - Customer opt-in URL<br />
						<b>opd</b> - Customer opt-in Date<br />
					</p>
					
					<p>Upon success will get back a JSON response with the status <b>queued</b> containing the message tracking id and the appended data for the number. Example response:</p>
					<pre style={{color: '#008'}}>{JSON.stringify(sample, null, 3).replace(/  /g,' ')}</pre>
					
					<p>If your message can not be queued, you will receive a JSON response with a status 'suspended', 'rejected', or 'error', and a details field explaining the message. Example responses:</p>
					
					<pre style={{color: '#008'}}>{JSON.stringify(sample2, null, 3).replace(/  /g,' ')}</pre>
					<pre style={{color: '#008'}}>{JSON.stringify(sample3, null, 3).replace(/  /g,' ')}</pre>
					
				</div>
				
			</div>
    );
    
  }
  
}
