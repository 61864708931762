"use strict";

// Npm modules
import {observable, computed, action, autorun} from "mobx";
import autobind from 'class-autobind';
import * as mobx from 'mobx';

// Our modules
var uiConfig = require('./appUiConfig.js');
var ajax = require('./ajaxHelper.js');
var grocer = require('./grocer.js');
var zEditStore = require('./zEditStore.js');

// ###########################
// Our primary app state class
// ###########################

module.exports = class AppStore {

	// ### DATA ###
	
	// Navigation
	@observable client = null; // Top level (account or db choice)
	@observable mode = null;   // Component level (report, edit, list, etc)
	@observable type = null;   // Document type
	@observable id = null;     // Document ID
	@observable didNav = false; // Nav state starts out undefined
	
	// Data and auth state
	@observable authData = null; // Nav state starts out undefined
	@observable modeData = null; // Nav state starts out undefined
	@observable loading = false; // Nav state starts out undefined
	@observable bizLoading = false; // Nav state starts out undefined
	@observable editor = null;
	
	// APP Functions
	@observable searchText = '';
	@observable lastSearch = '';
	@observable searching = false;
	@observable searchResult = null;
	@observable lookupField = '';
	@observable replyField = '';
	
	// Debug
	@observable debug = false;
	
	// ### CONSTRUCTOR ###
	
	constructor() {
					
		autorun(() => {
			if (this.didNav) {
				var path = this.currentPath;
				if (path !== window.location.pathname) window.history.pushState(null, null, path);
			}
		});
		
		if (! this.authData) this.doAuth();
		
  }
  
  // ### NAVIGATION ###
  
  @action navClient = (client, mode, type, id) => {
  	this.client = client || fail(new Error("client is required"));
  	this.mode   = mode || fail(new Error("mode is required"));
  	this.type   = type || null;
  	this.id     = id || null;
  	this.didNav =  true;
  	this.doFetchData();
  }
  
  @action nav = (mode, type, id) => {
  	this.mode   = mode || fail(new Error("mode is required"));
  	this.type   = type || null;
  	this.id     = id || null;
		this.lookupField = '';
		this.replyField = '';
  	this.didNav =  true;
  	this.doFetchData();
  }

  @action navHome = () => {
  	this.client = uiConfig.appDefault[0];
  	this.mode   = uiConfig.appDefault[1];
  	this.type   = uiConfig.appDefault[2];
  	this.id     = uiConfig.appDefault[3];
  	this.didNav =  true;
  	this.doFetchData();
  }

	@computed get currentPath() {
		var path = uiConfig.appRoot + this.client + '/' + this.mode;
		if (this.type) path += '/' + this.type;
		if (this.id) path += '/' + this.id;
		return path;
	}

	@computed get activeTab() {
		if (! this.type) return this.mode;
		return this.type;
	}

	// #######################
	// ### AUTHENITICATION ###
	// #######################

	// Look for auth
	
	doAuth() {
			
		ajax.doHttp('/app/auth', 'GET', null,
			auth => {
				if (auth.user) {
					this.authData = auth; // Save the auth data
					if (this.mode === 'login') store.navHome();
				}
				else {
					console.log("+ No Authentication.");
					console.log(auth);
					this.authData = null;
					// this.logout();
					window.location.href = "/index.html"; // google login version
				}
			}
		);
	}
	
	// Do a logout (google version)
	
	@action logout = () => {
			
		ajax.doHttp('/app/logout', 'GET', null,
			auth => {
				this.authData = null;
				var auth2 = gapi.auth2.getAuthInstance();
				auth2.signOut().then(function () {
					window.location.href = "/index.html"; // google login version
				});
			}
		);
	
	}
	
	// #######################
	// ### FETCH MODE DATA ###
	// #######################
	
	// Load the initial data for the featured component
	
	doFetchData() {
		
		var request = [];
		var gExtra = [];
		var mode = this.mode;
		
		// TEST MODE
		// ---------
		
		if (mode === 'test') {
			this.editor = new zEditStore({});
			this.modeData = null;
			return;
		}
		
		// SEARCH MODE
		// -----------
		else if (mode === 'lookup') {
		
			if (this.type) {
				this.editor = new zEditStore({phone: this.type});
				this.modeData = null;
				this.doLookup(this.type);
			}
			else {
				this.editor = new zEditStore({});
				this.modeData = null;
				return;
			}
		}
		
		// LIST MODE
		// ---------
		
		else if (mode === 'list') {
			var fields = []; // List of columns from the ui layout
			var requestAll =  false;
			var layout = uiConfig.listView[this.type] || fail(`Missing entry for "${this.type}" in uiConfig.listView`);
			for (var col in layout) {
				if (typeof layout[col]  === 'function') { requestAll = true; }
				else fields.push(layout[col]);
			}
			if (requestAll) fields = null;
			request.push({ action: 'list', db: this.client, docType: this.type, fields: fields });
		}
		
		// EDIT (detail) MODE
		// ------------------
		
		else if (mode === 'edit') {
		
			if (this.id === 'create') {
				request.push({ action: 'blank', db: this.client, docType: this.type });
				console.log('Optimize this for simple blank records plz!');
			}
			else {
				request.push({ action: 'get', db: this.client, docType: this.type, id: this.id });
			}
			
			// Extra groceries
			if (uiConfig.detailGroceries && uiConfig.detailGroceries[this.type]) {
				uiConfig.detailGroceries[this.type].forEach((g, i) => {
					gExtra[i + 1] = g.target || fail("Missing 'target' field in uiConfig:detailGroceries");
					g.db = this.client; // This is sorta bad, we are altering the config
					request.push(g);
				});
			}
		
		}
		
		// All other components default to a BIZ method
		
		else {
			var data = {
				client: this.client,
				mode:   this.mode,
				type:   this.type,
				id:     this.id,
			};
			request.push({ action: 'biz', method: mode, db: this.client, data: data });
		}
		
		// Execute the request
		
		if (request.length) {
		
			this.loading = true;
			this.modeData = null;
			this.editor = null;
		
			grocer.fetch(
				request,
				g => {
					this.loading = false;
					if (g.ok) {
						var data = { primary: g.data[0] };
						gExtra.forEach((t, i) => { if (i > 0) data[t] = g.data[i] });
						
						if (mode === 'edit') {
							this.modeData = data;
							this.editor = new zEditStore(g.data[0]);
						}
						else if (mode === 'list') {
							this.modeData = data;
						}
						else if (mode === 'dashboard') {
							this.modeData = g.data[0];
							this.editor = new zEditStore(g.data[0].account);
						}
						else if (mode === 'api') {
							this.modeData = g.data[0];
							this.editor = new zEditStore(g.data[0]);
						}
						else {
							this.modeData = g.data[0];
							this.editor = new zEditStore({});
						}
					}
					else {
						fail(g.err);
					}
				},
				e => {
					this.loading = false;
				},
				false,
			);
		
		}
	
	}

	// #####################
	// ### SAVE A RECORD ###
	// #####################
	
	// If there is a target rec in edit mode, save it
	
	@action saveEditor = () => {
	
		if (! this.editor) fail("App state does not currently have an editor component!");
		var myDoc = mobx.toJS(this.editor.data);
		// console.log("*** Saving");
		// console.log(myDoc);
		this.editor.changed = false;
		
		grocer.fetch(
			[{ action: 'save', db: this.client, docType: this.type, id: this.id, data: myDoc }],
			g => {
				this.loading = false;
				if (g.ok) {
					console.log(g);
				}
				else {
					fail(g.err);
				}
			},
			e => {
				this.loading = false;
			},
			false,
		);
		
	}
	
	// ##############
	// ### SEARCH ###
	// ##############
	
	// Quick search
	
	@action doSearch = () => {
	
		if (! this.searchText) return;
		if (this.searchText === this.lastSearch) return;
		
		// Parse the number		
		var phone = this.searchText || '';
		phone = phone.replace(/\D/g, '');

		if (! /^\d{10}$/.test(phone)) {
			alert("Please enter a 10 digit number");
			return;
		}
		
		this.searchText = '';
		this.lastSearch = '';
		this.nav('lookup', phone);
		
	}


	@action doLookup = (phone) => {
	
		if (! phone) return;
		
		grocer.fetch(
			[{ action: 'biz', method: 'lookup', db: this.client, data: { phone: phone } }],
			g => {
				this.searching = false;
				if (g.ok) {
					this.modeData = g.data[0];
				}
				else {
					fail(g.err);
				}
			},
			e => {
				this.searching = false;
			},
			false,
		);
		
	}
	
	// ###################
	// ### SEND A TEST ###
	// ###################
	
	// If there is a target rec in edit mode, save it
	
	@action doSmsTest = () => {
	
		if (! this.editor) fail("App state does not currently have an editor component!");
		var myDoc = mobx.toJS(this.editor.data);
		console.log("*** Sending");
		// console.log(myDoc);
		
		grocer.fetch(
			[{ action: 'biz', method: 'test', db: this.client, data: myDoc }],
			g => {
				if (g.ok) {
					this.modeData = g.data[0];
				}
				else {
					this.modeData = { status: 'Error', details: 'The server reported an error:\n' + g.err };
				}
			},
			e => {
				this.modeData = { status: 'Error', details: 'An error occurred connecting to the server:\n' + e };
			},
			false,
		);
		
	}
	
	@action doSendReply = (phone, msg) => {
		
		grocer.fetch(
			[{ action: 'biz', method: 'test', db: this.client, data: { phone: phone, text: msg } }],
			g => {
				if (g.ok) {
					var result = g.data[0];
					if (result.status !== 'OK') {
						alert(result.status + ': ' + result.details);
					}
					else {
						this.nav('lookup', phone);
					}
				}
				else {
					alert("Error: " + g.err);
				}
			},
			e => {
				alert("Error: " + e);
			},
			false,
		);
		
	}
	
	// #############
	// ## OPT OUT ##
	// #############
	
	@action doOptOut = (phone, status) => {
	
		if (! phone) return;
		if (! status) return;

		this.searchText = '';
		this.lastSearch = '';
		
		grocer.fetch(
			[{ action: 'biz', method: 'optout', db: this.client, data: { phone: phone, status: status } }],
			g => {
				this.searching = false;
				if (g.data[0] && g.data[0].phone) {
					this.nav('lookup', g.data[0].phone);
					this.modeData = g.data[0];
				}
				else {
					fail(g.err);
				}
			},
			e => {
			},
			false,
		);
		
	}

	// #############
	// ## OPT OUT ##
	// #############
	
	@action doBiz = (method, data) => {
		
		this.bizLoading = true;

		grocer.fetch(
			[{ action: 'biz', method: method, db: this.client, data: data }],
			g => {
				this.bizLoading = false;
				if (g.data[0]) {
					this.modeData = g.data[0];
				}
				else {
					fail(g.err);
				}
			},
			e => {
				this.bizLoading = false;
			},
			false,
		);
		
	}
	

}

function fail(msg) {
	alert(msg);
	throw new Error(msg);
}