// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autobind from 'class-autobind';

// Our modules
var uiConfig = require('../appUiConfig.js');

// Local style tweaks
var iconStyle = { paddingLeft: 12, paddingBottom: 0, height: 36 };

// ### Feature Components ### 

import List from './List.js';
import Edit from './Edit.js';

import Dashboard from './Dashboard.js';
import Test from './Test.js';
import Lookup from './Lookup.js';
import Logs from './Logs.js';
import Api from './Api.js';
import System from './System.js';
import Picker from './Picker.js';
import ReportBilling from './ReportBilling.js';
import InjectionStats from './InjectionStats.js';

class Loading extends React.Component {
	render() { return false; }	
}

var components = {

	list: List,
	edit: Edit,

	dashboard: Dashboard,
	test: Test,
	lookup: Lookup,
	system: System,
	picker: Picker,
	reports: ReportBilling,
	injectionStats: InjectionStats,
		
	logs: Logs,
	api: Api,
	
};

// ###########
// ### APP ###
// ###########

// The APP Class - Main Wrapper, Nav, and Container

@observer
export default class App extends React.Component {

	constructor() {
		super();
		autobind(this); // old school behavior of auto-binding
	}

	// Key bindings
	componentDidMount() {
		$(document.body).off('keydown.zeroApp'); // Just in case!
		$(document.body).on('keydown.zeroApp', this.handleKeyDown);
	}
	
	componentWillUnMount() {
		$(document.body).off('keydown.zeroApp', this.handleKeyDown);
	}
	
	handleKeyDown(event) {
		if (event.keyCode === 13) {
			var active = document.activeElement;
			if (active && (active.id === 'app-search-box')) {
				this.doSearch();
			}
		}
	}
	
	doSearch() {
		this.props.store.doSearch();
	}

	// ### RENDER ###

  render() {
    
  	// Use the app state (store) for navigation
  	var store = this.props.store;
  	// console.log(store);
 		
  	// prepare tabs from the config
 		var tabs = uiConfig.tabs.map( (t, i) => {
 			if (t.admin && !(store.authData && store.authData.admin)) return null;
 			return (<NavTab key={i} conf={t} store={store} />);
 		});
 		
 		// Select the feature to render (mode)
 		var Feature = components[store.mode];
 		if (! Feature) throw new Error(`Missing react component "${store.mode}"`);
 		if (store.loading) Feature = Loading;
 		
 		// Render the app wrapper and the featured component
    return (
      <div>
      
      	<nav className="navbar navbar-default app-topnav navbar-static-top" role="navigation">
      		<div className="container-fluid app-nav-container">
      			
      			<div className="navbar-header">
      				<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-nav" aria-expanded="false" aria-controls="main-nav"><span className="sr-only">Toggle navigation</span></button>
      				<NavLogo store={store} />
      			</div>
      			
      			<div className="" id="main-nav">
      				<ul className="nav navbar-nav">
      					{tabs}
      					<SearchBox store={store} />
      					<UserTab store={store} />
      				</ul>
      			</div>
      		
      		</div>
      	</nav>
        
        <div className='app-content-wrapper'>
        	<Feature store={store} config={uiConfig} />
        </div>
        
        <Debug store={store} />
        
      </div>
    );
  }
    
}

// ### NAV LOGO ###

@observer
class NavLogo extends React.Component {

	clickAdmin = () => {
		var conf = this.props.conf;		
		this.props.store.nav('picker');
	}

	render() {
	
		var store = this.props.store;
		var linkStyle = { paddingTop: '5px', color: "#e8132b" };
		
		// Admin
		
		if (store.authData && (store.authData.admin || store.authData.isLido)) {
			linkStyle = { paddingTop: '0px', color: "#e8132b" };
			return (
				<a className="navbar-brand app-brand-admin" style={linkStyle} onClick={this.clickAdmin}>
					<img src="/img/logo.png" style={iconStyle} /><p>{uiConfig.appTitle}<br /><span>{store.client}</span></p>
				</a>
			);
		}
		
		// User
		
		else {
			return (
				<a className="navbar-brand" style={linkStyle}>
					<img src="/img/logo.png" style={iconStyle} /><p>{uiConfig.appTitle}<br /></p>
				</a>
			);	
		}
	}

}

// ### NAV TABS ###

@observer
class NavTab extends React.Component {

	clickMe = () => {
		var conf = this.props.conf;		
		this.props.store.nav(conf.mode, conf.type);
	}

	render() {
	
		var conf = this.props.conf;
		var active = this.props.store.activeTab;
		var tabClass = 'app-nav';
		if ((conf.mode === active) || (conf.type === active)) tabClass='app-nav-active';
		
		return (
			<li className={tabClass} onClick={this.clickMe}>
				<a className="app-nav"><br /><i className={'fa fa-' + conf.icon} aria-hidden="true"></i>{conf.title}</a>
			</li>
		);
	}

}

// ### SEARCH BOX ###

@observer
class SearchBox extends React.Component {

	textChange = (event) => {
		this.props.store.searchText=event.target.value;
	}

	render() {
		var conf = this.props.conf;
		var store = this.props.store;
		var refBinder = (input) => { store.searchBox = input; } // ref={refBinder}
		
		return (
			<li key="nav-search" className='app-search'>
				<input
					id='app-search-box'
					type='text'
					tabIndex='1000'
					placeholder='Search...'
					value={store.searchText}
					onChange={this.textChange}
				/>
				<br />
				<button role="button" className="btn btn-xs btn-default app-button-affordance" onClick={store.doSearch}>Search <i className="fa fa-search-plus" aria-hidden="true"></i></button>
			</li>
		);
	}

}

// ### USER INFO/LOGOUT ##

@observer
class UserTab extends React.Component {

	render() {
	
		var store = this.props.store;
	
		if (! store.authData) return false;
		var user = store.authData;
		var nick = user.name || user.email.replace(/\@[^\.]+\.\w+$/, '');
		var img = user.img || '/img/user.png';
	
		return (
			<li className='app-nav-user'>
				<img src={user.picture} className='app-user-image' /><br />
				{nick}<br />
				<button role="button" className="btn btn-xs btn-default app-button-affordance" onClick={store.logout}>Logout <i className="fa fa-sign-out" aria-hidden="true"></i></button>
			</li>
		);
	}

}


// ### ADMIN DEBUG ###

@observer
class Debug extends React.Component {

	clickDebug = () => {
		this.props.store.debug = ! this.props.store.debug;
	}

	render() {
	
		var store = this.props.store;
	
		if (! store.authData) return false;
		if (! store.authData.isAdmin) return false;
		
		// Show debug
		if (store.debug) {	
			return (
				<div className='app-debug'>
					<div className="app-debug-button">
						<a onClick={this.clickDebug}><i className="fa fa-bug fa-lg"></i></a>
					</div>
					<div><pre style={{fontSize:9, color: '#228'}}>Path: {store.currentPath}</pre></div>
					<div><pre style={{fontSize:9, color: '#777'}}>{JSON.stringify(store, null, 3).replace(/  /g,' ')}</pre></div>
				</div>
			);
		}
		
		// Hide debug
		else {
			return (
				<div className='app-debug'>
					<div className="app-debug-button">
						<a onClick={this.clickDebug}><i className="fa fa-bug fa-lg"></i></a>
					</div>
				</div>
			)
		}
	}

}
