// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// The list object

@observer
export default class List extends React.Component {

  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	if (store.loading) return false;
  	if (! store.modeData) return false;
  	
 		var uiConfig = this.props.config;
 		var data = store.modeData.primary;
 		
 		// Look for controls (add, filters, etc)
 		var controls = [];
 		
 		if (uiConfig.listOptions && uiConfig.listOptions[store.type]) {
 			var myConf = uiConfig.listOptions[store.type];
 			var addButtonText = myConf.addButton;
			if (addButtonText) {
				var go = store.nav.bind(store, 'edit', store.type, 'create');
				controls.push(<ListButton key='add' name={addButtonText}  go={go} icon='fa-plus-square' />);
			}
 		}
 		
 		// Parse the layout
 		var headers = [];
 		var layout = uiConfig.listView[store.type];
 		headers.push(<th key='ctrls'></th>);
 		for (var key in layout) {
			headers.push(<th key={key}>{key}</th>);
		}
 
		// Sorting
		if (data && uiConfig.listSort && uiConfig.listSort[store.type]) {
			data.sort(uiConf.listSort[store.type]);
		}

		// Rows
		var rows = [];
		if (data) {
			data.forEach(doc => {
				var go = store.nav.bind(store, 'edit', store.type, doc._id);
				rows.push(
					<Row
						key={doc._id}
						layout={layout}
						doc={doc}
						edit={go}
					/>);
			});
		}
		
		var header = (
			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-9 zero-detail-text">
						{store.type}
					</div>
					<div className="col-sm-3 app-mode-buttons">
						{controls}
					</div>
				</div>
			</div>
		);
 		 		
    return (
			<div className="container-fluid">
		
				{header}

				<table className='table table-striped table-hover table-condensed'>
					<tbody>
					<tr>
						{headers}
					</tr>
					{rows}
					</tbody>
				</table>
			
			</div>
    );
  }
  
}

class Row extends React.Component {

	render() {
		
		var layout = this.props.layout;
		var columns = [];
		
		columns.push(
  		<td key='edit'><a onClick={this.props.edit} className='btn btn-default btn-xs app-button-affordance'><i className="fa fa-pencil-square-o" style={{fontSize: 14}}></i> Edit</a>&nbsp;</td>
  	);
		
  	for (var key in layout) {
  		var field = layout[key];
  		var value;
  		if (typeof field  === 'function') value = field(this.props.doc);
  		else value = this.props.doc[layout[key]];
  		columns.push(<td key={key}>{value}</td>);
  	}
  	
    return (
    	<tr>
    		{columns}
    	</tr>
    );
    
	}

}

// ### BUTTONS ###

class ListButton extends React.Component {

	render() {
		  	
    return (
    	<a className='btn btn-default btn-sm action-btn app-button-affordance' onClick={this.props.go}><i className={"fa " + this.props.icon}></i> {this.props.name}</a>
    );
    
	}

}
