// THE GROCER
// Connects to the grocer 
// =============================================

// SET SERVER URL
// --------------

// Default url is /app/grocer but you can change it if you like
// The grocer is shared by every package, so setting that URL
// will work for all instances
 
var serverUrl = '/app/grocer';

var Stash = {};

exports.setUrl = function setUrl(url) {
	if (! url) throw new Error("Missing error ");
	serverUrl = url;
}

// DO LIST
// =======

// Do a list of requests
// Expects a list of requests

// The grocer handles a list of requests for data objects or business methods

// DEFAULT DATA METHODS

// Get record - { action: 'get', doctype: 'example', id: docId } --> doc or null
// Find record - { action: 'get', doctype: 'example', field: 'fieldName', vale: 'matchValue' } --> doc or null
// List Records - { action: 'list', doctype: 'example', start: count, limit: count, fields: [ optional fields to return ] } --> list (possibly empty)
// Create/Update Record - { action: 'save', doctype: 'example', id: docId, data: object } --> rec ID of new or saved doc

// CUSTOM BIZ method

// { method: 'biz', params: { method: 'name', data: { } } }

// USAGE:

// It's a handler, it expects JSON in the body. It will take a single object or an array.

// RESULTS

// Results are an array of responses, in the same order as requests

exports.fetch = function fetch(list, successFunc, errFunc, alertOnFail) {

	// Make an error to get the stack trace
	var oldErr = new Error('in grocer:fetch');
	var oldStack = oldErr.stack;
	
	if (! list) { return exports.handleError(new Error('Missing list')); }
	
	// Look for stashed responses
	// If every response is stashed, return immediately
	
	var stashedCount = 0;
	var localResult = { ok: 1, data: [] };
	// console.log(Stash);
	list.forEach(function(req){
		// console.log("<stash-check> %s", req.action);
		if (req.action === 'get') {
			// console.log("<fetching> %s/%s", req.docType, req.id);
			if (Stash[req.docType] && Stash[req.docType][req.id]) {
				var doc = Stash[req.docType][req.id];
				var age = Math.floor((Date.now() - doc.stashed) / 100) / 10;
				if (age > 3) {
					console.log('- Stash entry %s:%s discarded after %s sec', req.doctype, req.id, age);
					delete Stash[req.docType][req.id];
				}
				else {
					console.log('- Stash entry %s:%s used (%s sec old)', req.docType, req.id, age);
					localResult.data.push(doc);
					delete Stash[req.docType][req.id];
					stashedCount += 1;
				}
			}
		}
	});
	
	if (stashedCount === list.length) {
		console.log('- %s requests served from stash, server call skipped', stashedCount);
		successFunc(localResult);
		return;
	}
	
	var json = JSON.stringify(list);
	// console.log("grocer.js> CLIENT SIDE GROCER SENDING REQUEST");
	// console.log(json);
	
	$.ajax({
	
		type:     "POST",
		url:      serverUrl, 
		data:     json,
		contentType: "application/json",
		
		// Error responses
		error: function(r, status, myErr) {
			if (errFunc) errFunc(myErr); // Call the caller's error func
			handleError(myErr, oldStack); // log to console with stack trace
			alert(myErr); // An optional alert, remove for production
		},
		
		// Valid responses
		success: function(response) {
			
			var result = jQuery.parseJSON(response);
			if (! result.ok) {
				if (errFunc) errFunc(result.err); // Call the caller's error func
				handleError('SERVER ' + result.err, oldStack); // log to console with stack trace
				if (alertOnFail) alert('SERVER ' + result.err);
			}
			
			// Handle stash response, which caches a result for a future GET
			if (result.stash) {
				result.stash.forEach(function(item) {
					if (! Stash[item.docType]) Stash[item.docType] = {};
					Stash[item.docType][item.id] = item.doc;
					Stash[item.docType][item.id].stashed = Date.now();
					// console.log("STASHED: %s:%s", item.docType, item.id);
					// console.log(Stash);
				});
			}
			
			if (successFunc) successFunc(result); 
		},
		
	});

}

// HANDLE ERRORS
// -------------

// Expects an error object, but can also handle a string

var handleError = function handleError(err, extra) {

	var msg;
	console.error(err);
	if (extra) { console.error(extra); }
	
}
