// Fancy Editor Component
// ----------------------

"use strict";

// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autobind from 'class-autobind';

var moment = require('moment');

// Global settings

var layout = { masterCellWidth: 2,}

var layoutVars = {
	cellWidth: 1,
	defaultCellWidth: 1,
}

var layoutOk = Object.keys(layoutVars).join(', ');

var djsConfig = {
	// addRemoveLinks: true,
	maxFilesize: 1,
	maxThumbnailFilesize: 1,
	thumbnailWidth: 120,
	thumbnailHeight: 60,
	maxFiles: 1,
	dictDefaultMessage: "Drag image here or click to upload",
};

@observer
export default class ZEdit extends React.Component {

	constructor() {
		super();
		autobind(this);
	}

	setField(event) {
		this.props.store.data.set(event.target.id, event.target.value);
	}
	
	addNote(fieldName) {
		var notesData = this.props.store.data.get(fieldName);
		if (! notesData) var theNotes = [];
		else theNotes = JSON.parse(notesData);
		theNotes.push({
			d: new Date(),
			u: this.props.user.user,
			un: this.props.user.name,
			t: "",
		});
		var editId = `${fieldName}${theNotes.length - 1}`;
		this.props.store.edits.set(editId, true);
		this.props.store.data.set(fieldName, JSON.stringify(theNotes));
	}

	editNote (editId) {
		this.props.store.edits.set(editId, true);
	}
	
	setNote(id, event) {
		var fieldName = event.target.id;
		var newText = event.target.value;
		var notesData = this.props.store.data.get(fieldName);
		if (notesData) {
			var theNotes = JSON.parse(notesData);
			theNotes[id].txt = newText;
			this.props.store.data.set(fieldName, JSON.stringify(theNotes));		
		}
	}

	checkToggle(fieldName) {
		var isChecked = this.props.store.data.get(fieldName) || false;
		this.props.store.data.set(fieldName, ! isChecked);
	}
	
	dateStamp(fieldName) {
		this.props.store.data.set(fieldName, moment().format("YYYY-MM-DD HH:mm"));
	}
	
	secToggle(sectionName) {
		var isChecked = this.props.store.sections.get(sectionName) || false;
		this.props.store.sections.set(sectionName, ! isChecked);
	}

	unProtect(field) {
		this.props.store.unProtect.set(field, true);
	}

	markDirty(event) {
		if (! this.props.store.changed) this.props.store.changed = true;
	}

	markClean(event) {
		this.props.store.changed = false;
	}

	doFocus() {
		this.props.store.doFocus = true;
	}
	
	doLink(key) {
		if (this.props.nav) {
			this.props.nav(this.props.store.links[key]);
		}
		else {
			console.log('*** Caller provided no nav prop!');
		}
	}

	// RENDER WRAPPER WITH TRY/CATCH	
	
	render() {
	
		try {
			var theThing = this.renderMe()
		}
		catch(e) {
			alert('ZEDIT RENDER ERROR: ' + e);
			throw e;
		}
	
		return theThing;
	
	}
	
	// INSANE COMPLICATED RENDERER
	
	renderMe() {

		var self = this;
				
		var myStore = this.props.store;
		var rec = this.props.store.data;
		
		var formContents = [];
		var oc = self.setField;
		var dsEvents = {}; // For image upload magic

		// Build an edit layout
		var sectionType;
		var sectionData;
		var sectionSize = 0;
		var sectionName = '';
		var sectionHr = true;

		var sectionId = 0;
		var labelId = 0;
	
		// SECTION FUNCTIONS

		// RENDER CELL
		// function() Add a cell to the current section
	
		var addField = function(element, field) {
			sectionSize += 1; // Number of fields, different than column width
			labelId += 1;
		
			var label = element.label || '';
			var notes = element.notes || '';

			var myCaption;
			if (label && notes) myCaption = <span key={'zl_' + labelId} className='zero-edit-label'><b>{label}</b> - {notes}</span>;
			else if (label) myCaption = <span key={'zl_' + labelId} className='zero-edit-label'><b>{label}</b></span>;
		
			if (! layout.cellWidth) throw new Error("cellWidth is empty! Declare a section before you start creating cells.");
		
			var myDivKey = 'zf_' + labelId;
			var myWidth = element.width || layout.cellWidth;
			var cellClass = `col-sm-${myWidth} zero-cell`;
			if (element.type === 'blank') var cell = <div key={myDivKey} className={cellClass}>&nbsp;</div>;
			else if (element.type === 'break') var cell = '';
			else var cell = <div key={myDivKey} className={cellClass}>{myCaption}{field}</div>;
		
			sectionData.push({
				width: myWidth,
				data: cell,
				name: label,
				type: element.type
			});
		}
	
		// RENDER SECTION HEADER
		// function() Render a section header with expand/collapse button
		var renderSecHeader = function(name, id) {

			if (myStore.sections.get(name)) {
				return (
					<div key={'zr_head_' + id} className="zero-section-open">
						<a onClick={self.secToggle.bind(self, name)}><span className="glyphicon glyphicon-minus-sign zero-section-glyph" aria-hidden="true"></span></a>
						&nbsp;<a onClick={self.secToggle.bind(self, name)}>{name}</a>
					</div>
				);
			}
			else {
				return (
					<div key={'zr_head_' + id} className="zero-section-open">
						<a onClick={self.secToggle.bind(self, name)}><span className="glyphicon glyphicon-plus-sign zero-section-glyph" aria-hidden="true"></span></a>
						&nbsp;<a onClick={self.secToggle.bind(self, name)}>{name}</a>
					</div>
				);
			}
		
		};

		// RENDER CURRENT SECTION
		// function() Render currently built section
		var renderSection = function(isLast) {
		
			sectionId += 1;
			if (sectionSize <= 0) throw new Error("Trying to render an empty section");
		
			// Render the collapse bar / title
			if (sectionName) {
				formContents.push(renderSecHeader(sectionName, sectionId));
				sectionHr = false;
			}
		
			// If it is collapsed, render nothing
			// otherwise render all the columns
		
			var showSection = true;
			if (sectionName && (! myStore.sections.get(sectionName))) showSection = false; // Hide if it is not marked open
		
			if (showSection) {
			
				var rowCount = 0;
				var fieldCount = 0;
			
				var rowSize = 0;
				var rowContent = [];
			
				var addRow = function() {
					rowCount += 1;
					var rowKey = "zr_" + sectionId + "_" + rowCount;
					formContents.push(<div key={rowKey} className="row zero-row">{rowContent}</div>);
					rowContent = [];
					rowSize = 0;
				
				};
			
				// Loop through and create as many rows as we need
				// to fit all the cells			
			
				sectionData.forEach(function(cell) {
					var remaining = 12 - rowSize;
					if (cell.width > remaining) addRow(); // If this is too big, render the row first
					if (cell.type === 'break') {
						addRow();
						return;
					}		
					fieldCount +=1;
					var colKey = "zc_" + sectionId + "_" + fieldCount;
					rowContent.push(cell.data);
					rowSize += cell.width;
				});
			
				// Push the last cell
				if (rowSize > 0) addRow();
			
			}
						
			if ((! isLast) && sectionHr) formContents.push(<hr key={'zh' + sectionId} />);
		
		}

		// -------------
		// LAYOUT PARSER
		// ------------- 

		var count = 0;

		self.props.template.forEach(e => {

			var key = 'z_' + count;
			var eProtect = e.protect && (! myStore.unProtect.get(e.field));
			var eType = e.type;
			var myValue = rec.get(e.field) ||'';
			if (e.field) key += e.field;
		
			// legacy stuff, just in case
			if (eType === 'dateProtected') {
				eType = 'date';
				eProtect = ! myStore.unProtect.get(e.field);
			
			}
			if (eType === 'protected') {
				eType = 'text';
				eProtect = ! myStore.unProtect.get(e.field);
			}
		
			// Autofocus on load
			var ref = null;
			if (e.autoFocus) {
				ref = uiElement => {
					if (uiElement && myStore.doFocus) {
						uiElement.focus();
						myStore.doFocus = false;
					}
				};
			}
		
			// Change layout settings
		
			if (eType === 'layout') {
				for (var f in e) {
					if (f == 'type') continue;
					if (! layoutVars[f]) throw new Error(`Layout setting "${f}" is not supported. Valid settings are: ${layoutOk}`);
					layout[f] = e[f];
				}
			}
	
			// * New Section
			else if (eType === 'section') {
		
				if (sectionSize) renderSection(); // Render the previous section
			
				// Set up the data for the new section
				sectionSize = 0;
				sectionData = [];
			
				// Named Section
				if (e.name) sectionName = e.name;
				else sectionName = '';
			
				// HR divider
				if (e.noDivider) sectionHr = false;
				else sectionHr = true;
			
				// Cell width settings
				if (! layout.defaultCellWidth) layout.defaultCellWidth = layout.masterCellWidth;
				if (e.cellWidth) layout.cellWidth = e.cellWidth;
				else if (e.mode === 'single') layout.cellWidth = 12;
				else layout.cellWidth = layout.defaultCellWidth;		
				
				// pre-open sections
				if (e.open && sectionName) {
					if (! myStore.sections.has(sectionName)) {
						myStore.sections.set(sectionName, true)
					}
				}			
			
			}

			// * BLANK SPACE
			else if (eType === 'blank')  {
				addField(e, '')
			}
		
			// break
			else if (eType === 'break')  {
				addField(e, '')
			}
		
			// * Static field
			else if (eType === 'static')  {
				addField(e, <p key={key} className="form-control-static zero-edit-static">{myValue}</p>)
			}

			// * Static field
			else if (eType === 'json')  {
				var myDisplay = JSON.stringify(myValue);
				addField(e, <pre key={key} className="form-control-static zero-edit-static">{myDisplay}</pre>)
			}
			
			// * Static checkbox
			else if (e.type === 'checkboxStatic') {
				var checked = ! ((! myValue) || (myValue === 'false'));
				var myToggler = self.checkToggle.bind(self, e.field);
				var myIcon = 'fa-square-o';
				var checkStr = 'False';
				if (checked) {
					myIcon = 'fa-check-square-o';
					checkStr = 'True';
				}
			
				addField(e,
					<p key={key} className="form-control-static zero-edit-checkbox-static">
						<i className={"fa fa-lg " + myIcon} aria-hidden="true"></i> {checkStr}
					</p>
				);
			}

			// * Protected field
			else if (eProtect) {
							
				var myField;
				if ((eType === 'date') && myValue && moment(myValue).isValid()) {
					myField = <div className="zero-edit-protected-text">{moment(myValue).format("YYYY-MM-DD")}</div>
				}
				else if (myValue) myField = <div className="zero-edit-protected-text">{myValue}</div>
				else myField = <div className="zero-edit-protected-text">&nbsp;</div>
		
				addField(e, (
					<div key={key} className="zero-edit-protected">
						{myField}
						<div className='zero-unprotect'>
							<a onClick={self.unProtect.bind(self, e.field)}><i className="fa fa-lg fa-lock zero-section-glyph" aria-hidden="true"></i></a>
						</div>
					</div>
				));
								
			}
		
			// * Linked Field
			else if (eType === 'link')  {
		
				var link = '';
				var docSingle = e.docType.replace(/s$/, '');
			
				if (e.data && e.data._id) {
					if (! e.docType) throw new Error("Missing doctype in 'link' ui element at " + JSON.stringify(e));
					var innerLabel = ucFirst(docSingle);
					if (e.display && e.data[e.display]) innerLabel = e.data[e.display];
					var icon = 'fa-arrow-circle-o-right';
					var target = '';
					if (e.pop) {
						target ="_blank";
						icon = 'fa-external-link';
					}
					link = self.props.baseNav + docSingle + '/' + e.data._id;
					myStore.links[key] = link;
				}
		
				if (link) {
					addField(e, (
						<div key={key} className="zero-edit-protected">
							<div className="zero-edit-protected-text">
								<a onClick={self.doLink.bind(self, key)}>Go to {innerLabel}</a>
							</div>
							<div className='zero-unprotect'>
								<a onClick={self.doLink.bind(self, key)}><i className={"fa fa-lg zero-section-glyph " + icon} aria-hidden="true"></i></a>
							</div>
						</div>
					));
				}
				else if (e.showEmpty) {
					addField(e, (
						<div key={key} className="zero-edit-protected">
							<div className="zero-edit-protected-text">
								No {docSingle} linked
							</div>
							<div className='zero-unprotect'>
								<i className="fa fa-lg zero-section-glyph fa-times-circle-o disabled" aria-hidden="true"></i>
							</div>
						</div>
					));
				}
		
			}
	
			// * Text field
			else if (eType === 'text')  {
				addField(e, <input key={key} tabIndex={count} type='text' autoComplete="off" name={e.field} id={e.field} className="form-control" onChange={oc} value={myValue} ref={ref}/>)
			}

			// * Text edit area
			else if (eType === 'textarea')  {
				addField(e, <textarea key={key} tabIndex={count} autoComplete="off" className="form-control" name={e.field} id={e.field} onChange={oc} value={myValue} ref={ref}></textarea>);
			}

			// * Date field (Special text field for dates)
			else if (eType === 'date') {
			
				var myStamper = (
					<span className='zero-unprotect-date'>
						<a onClick={self.dateStamp.bind(self, e.field)}><span className="glyphicon glyphicon-ok zero-section-glyph" aria-hidden="true"></span></a>
					</span>
				);
				
				var dateClass = 'zero-edit-date';
			
				if (! (myValue && moment(myValue).isValid())) {
					dateClass = 'zero-edit-date-bad';
				}
			
				addField(e,
					<div key={key}>
						<nobr><input tabIndex={count} type='text' autoComplete="off" name={e.field} id={e.field} className={"form-control " + dateClass} onChange={oc} value={myValue} placeholder="YYYY-MM-DD" ref={ref} />
						{myStamper}
						</nobr>
					</div>
				);
			}

			// * Special Text area for SMS messages
			else if (eType === 'textsms')  {
				var smsLen = myValue.length;
				var smsStyle = "zero-green";
				if (smsLen > 140) smsStyle = "zero-yellow";
				if (smsLen > 160) smsStyle = "zero-red";
				addField(e, <div className="zero-sms"><textarea key={key} tabIndex={count} autoComplete="off" className="form-control" name={e.field} id={e.field} onChange={oc} value={myValue} ref={ref}></textarea><div className={smsStyle}>{smsLen} Chars / {160 - smsLen} Remaining</div></div>);
			}
	
			// * Checkbox
			else if (eType === 'checkbox') {
				var checked = ! ((! myValue) || (myValue === 'false'));
				var myToggler = self.checkToggle.bind(self, e.field);
				var myIcon = 'fa-square-o';
				var checkStr = 'False';
				if (checked) {
					myIcon = 'fa-check-square-o';
					checkStr = 'True';
				}
			
				addField(e,
					<p key={key} className="form-control-static zero-edit-checkbox" onClick={myToggler}>
						<i className={"fa fa-lg " + myIcon} aria-hidden="true"></i> {checkStr}
					</p>
				);
			}

			// * Dropdown 1: Just an array of values
			else if (eType === 'dropdown')  {
				if (! e.options) throw new Error("'dropdown' type requires 'options' array");
				if (! e.options.length) {
					throw new Error("'dropdown' type requires 'options' array");
				}
				var options = [];
				if (! myValue) options.push(<option key={key + 'o_blank'} value='' disabled></option>);
				for (var i = 0; i < e.options.length; i++) {
					var fkey = key + "o" + i;
					options.push(<option key={fkey} value={e.options[i]}>{e.options[i]}</option>);
				}
				addField(e, <select key={key} tabIndex={count} id={e.field} className='form-control my-smaller-input' onChange={oc} value={myValue} ref={ref}>{options}</select>);
			}

			// * Dropdown 2: Hash keys as values
			else if (eType === 'dropdown2')  {
				if (! e.options) throw new Error("'dropdown' type requires 'options' hash in " + JSON.stringify(e));
				if (typeof e.options !== 'object') throw new Error("'dropdown' type requires 'options' hash in " + JSON.stringify(e));
				var options = [];
				if (! myValue) options.push(<option key={key + 'o_blank'} value='' disabled></option>);
				for (var optId in e.options) {
					var fkey = key + "o" + optId;
					if (myValue === optId) console.log('* %s: %s', optId, e.options[optId]);
					options.push(<option key={fkey} value={optId}>{e.options[optId]}</option>);
				}
				addField(e, <select key={key} tabIndex={count} id={e.field} value={myValue} defaultValue={myValue} className='form-control my-smaller-input' onChange={oc} ref={ref}>{options}</select>);
			}

			// * Magic editor (Using Ckedit)
			else if (eType === 'editor')  {
				var invoke = self.invokeEditor.bind(self, e.field);
				addField(e, <textarea key={key} tabIndex={count} rows='1' autoComplete="off" className="form-control zero-edit-magic" name={e.field} id={e.field} defaultValue={myValue} placeholder="Add Content..." onClick={invoke} onFocus={invoke} style={{resize: 'none'}} ref={ref}></textarea>);
			}
	
			// * Image upload field
			else if (eType === 'image')  {
	
				dsEvents[e.field] = function(fieldName){
					return {
						success: function dzSuccess(file) {
							var result = JSON.parse(file.xhr.responseText);
							rec.set(fieldName, result.url);
						}
					}
				}(e.field);
		
				var imgPreview;
				if (myValue) {
					imgPreview = <img src={myValue} className="zero-img-preview" />
				}
		
				addField(e,
					<table><tbody><tr>
						<td style={{verticalAlign: 'top'}}><input key={key} tabIndex={count} type='text' name={e.field} id={e.field} onChange={oc} value={myValue} autoComplete="off" style={{verticalAlign: 'top',width: 251}}/></td>
						<td style={{paddingLeft: "10px"}}>{imgPreview}</td>
					</tr></tbody></table>
				);
			}
		
			// * Notes Field
			else if (eType === 'notes')  {
		
				var coolNotes = true;
				var noteStruc;
			
				if (! myValue) noteStruc = [];
				else {					
					try {
						noteStruc = JSON.parse(myValue);
					}
					catch(e) {
						console.log(e);
						coolNotes = false;
					}
				}
			
				// Cool notes mode
				if (coolNotes) {
			
					var noteEntries = [];
					var noteid = 0;
				
					if (noteStruc.length > 0) {
						noteEntries.push(<tr key='h'><th width='20%'>Date</th><th width='20%'>User</th><th width='60%'>Entry</th></tr>);
					}
				
					noteStruc.forEach(function(note){
						var theDate = '-';
						if (moment(note.d).isValid()) theDate = moment(note.d).format("YYYY-MM-DD HH:mm");
						var editId = `${e.field}${noteid}`;
						if (myStore.edits.get(editId)) {
							var txt = (<textarea key={key} tabIndex={count} autoComplete="off" className="zero-notes-text" name={e.field} id={e.field} onChange={self.setNote.bind(self, noteid)} value={note.txt || ''} placeholder='Enter note here...' />);
						}
						else {
							var txt = (
								<div key={key} className="zero-notes-protected">
									<div className="zero-notes-protected-text">{note.txt}</div>
								<div className='zero-notes-unprotect'>
									<a onClick={self.editNote.bind(self, editId)}><i className="fa fa-lg fa-pencil-square-o zero-section-glyph" aria-hidden="true"></i></a>
								</div>
							</div>
							);
						}
						noteEntries.push(
							<tr key={noteid}>
								<td className="zero-notes-td" key='d' width='20%'><nobr>{theDate}</nobr></td>
								<td className="zero-notes-td" key='u' width='20%'>{note.un}</td>
								<td className="zero-notes-td" key='t' width='60%'>{txt}</td>
							</tr>
						);
						noteid += 1;
					});
				
					noteEntries.push(<tr key='f'><th colSpan='3' className='zero-note-controls'>Add a Note <a onClick={self.addNote.bind(self, e.field)}><i className="fa fa-lg fa-plus-square" aria-hidden="true"></i></a></th></tr>);
				
					addField(e,
						<table className='zero-notes table table-condensed'><tbody>
						{noteEntries}
						</tbody></table>
					);
			
				}
			
				// If no cool notes, do a text area
				else {
					addField(e, <textarea key={key} tabIndex={count} autoComplete="off" className="form-control" name={e.field} id={e.field} onChange={oc} value={myValue} ref={ref}></textarea>);
				}
		
			}
		
			// Custom React component
			// Caller can inject custom components, and they get rendered here
			else if (eType === 'custom')  {
				addField(e, e.widget);
			}
		
			else {
				var msg = `Unrecognized element type "${eType}"`;
				alert(msg);
				throw new Error(msg);
			}
		
			// Counter
			count += 1;
			
		});

		if (sectionSize > 0) renderSection(true); // Render a final section
		
		// Return the edit form (note: it's not actually a form, just a list of fields)

		return (
			<div key='zedcon1' className="content">
				<div key='zedcon2' className="container-fluid zero-edit-container">
					{formContents}
				</div>
			</div>
		);

	}


}
