"use strict";

// Npm modules
var moment = require('moment');

// ### UI CONFIG ##
exports.appRoot    = '/app/';
exports.appDefault = ['sys', 'dashboard', null, null];
exports.appTitle   = 'MobileApi';

exports.ckEdit = false; // Turn off the CKedit overheads


// Some functions

var shortCodes = {

	"mta2_aptness_64088": "64088 AptNess Via MTA2",

	'cdy34795': "347-95",
	'cdy85760': "857-60",
	'cdy65720': "657-20",
	'cdy91096': "910-96",
	'cdy23329': "233-29",
	'cdy97830': "978-30",
	// 'cdy48794': "487-94",
	'cdy42109': '421-09',
	'cdy52030': '520-30',
	
	'3ci66152': '661-52',
	'3ci64088': '640-88',

	"10dlc_NOC_MTA019a": "10DLC - MTA 019a ICI",
	"10dlc_NOC_MTA019b": "10DLC - MTA 019b ICI",

	"10dlc_NOC_MTA018a": "10DLC - MTA 018 A",
	"10dlc_NOC_MTA018b": "10DLC - MTA 018 B",
	"10dlc_NOC_MTA018c": "10DLC - MTA 018 C",

	'10dlc_NOC_RL003': '10DLC - Market Bullet RL003',
	'10dlc_NOC_RL003b': '10DLC - Market Bullet RL003b',
	'10dlc_NOC_RL003c': '10DLC - Market Bullet RL003c',
	'10dlc_NOC_RL003d': '10DLC - Market Bullet RL003d',

	"10dlc_NOC_RL011_keto": "10DLC - OnlyMine keto",
	"10dlc_NOC_RL011_skin": "10DLC - OnlyMine skin",
	"10dlc_NOC_RL011_AC": "10DLC - OnlyMine AC",
	"10dlc_NOC_RL011_Mosquito": "10DLC - OnlyMine Mosquito",
	"10dlc_NOC_RL011_BrainPill": "10DLC - OnlyMine BrainPill",
	"10dlc_NOC_RL011_StopWatt": "10DLC - OnlyMine StopWatt",
	"10dlc_NOC_RL011_AutoIns": "10DLC - OnlyMine AutoIns",
	"10dlc_NOC_RL011_ClassALaw": "10DLC - OnlyMine ClassALaw",
	"10dlc_NOC_RL011_Astro": "10DLC - OnlyMine Astro",
	"10dlc_NOC_RL011_SkinTag": "10DLC - OnlyMine SkinTag",
	"10dlc_NOC_RL011_UnbreakBra": "10DLC - OnlyMine UnbreakBrain",
	"10dlc_NOC_RL011_BloodP": "10DLC - OnlyMine BloodP",
	"10dlc_NOC_RL011_Debt": "10DLC - OnlyMine Debt",
	"10dlc_NOC_RL011_LifeIns": "10DLC - OnlyMine LifeIns",
	"10dlc_NOC_RL011_HealthIns": "10DLC - OnlyMine HealthIns",
	"10dlc_NOC_RL011_TrumpCoin": "10DLC - OnlyMine TrumpCoin",
	"10dlc_NOC_RL011_LTApparel": "10DLC - OnlyMine LTApparel",
	"10dlc_NOC_RLTest_a": "10DLC - Test A",
	"10dlc_NOC_RLTest_b": "10DLC - Test B",
	"10dlc_NOC_RLTest_c": "10DLC - Test C",

	"10dlc_NOC_100_SC": "Shortcode NOC100 - 21716",

	"10dlc_NOC_RL016a": "10DLC - mymatchsource_016a",
	"10dlc_NOC_RL016b": "10DLC - mymatchsource_016b",
	"10dlc_NOC_RL016c": "10DLC - mymatchsource_016c",
	"10dlc_NOC_RL016d": "10DLC - mymatchsource_016d",
	"10dlc_NOC_RL016e": "10DLC - mymatchsource_016e",
	"10dlc_NOC_RL016f": "10DLC - mymatchsource_016f",

	"10dlc_NOC_RL012_ApNssJobs": "10DLC - NOC_RL012_ApNssJobs",
	"10dlc_NOC_MTA018d": "10DLC - MTA018d",

	'cdy23329-leadwerxmortgage': "233-29 (leadwerxmortgage)",
	'cdy23329-leadwerxsolar': "233-29 (leadwerxsolar)",
	'cdy23329-leadwerxautofin': "233-29 (leadwerxautofin)",
	'cdy23329-leadwerxcreditcards': "233-29 (leadwerxcreditcards)",
	'cdy23329-leadwerxpersloans': "233-29 (leadwerxpersloans)",

};

var providers = {
	'MTA2': 'MTA2',
	'cdyne': 'CDYNE (Default)',
	'3ci': '3ci',
	'10dlc': '10DLC',
	'bandwidth': 'Bandwidth 800 Rotation',
};

var groups = {
	'bm1': 'Bandwidth MT Group 1',
	'bm2': 'Bandwidth MT Group 2',
	'bm3': 'Bandwidth MT Group 3',
}

var providerCodes = {
	cdyne: 'CDYNE',
	bandwidth: 'Bandwidth',
};

var numberStatus = {
	active: 'Active',
	reserved:  'Reserved',
	flagged:   'Flagged as Spam',
	suspended: 'Suspended',
};


// App Main Tabs
exports.tabs = [
	{ title: 'Home', icon: 'tachometer', mode: 'dashboard', },
	{ title: 'Test', icon: 'mobile', mode: 'test', type: "tasks" },
	{ title: 'Lookup', icon: 'search-plus', mode: 'lookup' },
	{ title: 'Logs', icon: 'tasks', mode: 'logs' },
	{ title: 'Api', icon: 'cog', mode: 'api' },
	{ title: 'Injection', icon: 'bar-chart', mode: 'injectionStats', admin: true },
	{ title: 'Cdyne', icon: 'list-alt', mode: 'reports', admin: true },
	{ title: 'System', icon: 'heartbeat', mode: 'system', admin: true },
	{ title: 'Accounts', icon: 'address-card-o', mode: 'list', type: "accounts", admin: true },
	{ title: 'Users', icon: 'user-circle-o', mode: 'list', type: "users", admin: true },
	
];

// List views
exports.listView = {

	users: {
		Name: "name",
		Client: "clientCode",
		"Client Mode": function(d) { return d.isClient ? 'Yes' : 'No'; },
		Approved: function(d) { return d.approved ? 'Yes' : 'No'; },
		Admin: function(d) { return d.isAdmin ? 'Yes' : 'No'; },
	},

	accounts: {
		Code: 'code',
		Name: "name",
		Credits: "credits",
		"Short Code": d => { return (d.sendGroup && groups[d.sendGroup]) || shortCodes[d.shortCode] || 'Default' },
	},
	
	outboundNumbers: {
		'Number': "number",
		'Provider': "provider",
		'Status': 'status',
		'Group': "group",
		'Errors': "errors",
		'Sent Today': "sentToday",
		'Daily Limit': "dailyLimit",
	},
	
};


// List options

exports.listOptions = {

	outboundNumbers: {
		addButton: "Add a Number",
	},
	
};


exports.detailGroceries = {

	users: [
		{ action: 'list', docType: 'accounts', target: 'accounts' },
	],

};

function fSelectClients(rec, extra) {
	if (! extra.accounts) {
		throw new Error("* Expected 'accounts' in extra");
	}
	var select = {};
	extra.accounts.forEach( c => { select[c.code] = c.name });
	return select;
}


// Edit Views

exports.editViews = {

	outboundNumbers: [

	  { type: "section", cellWidth: 3 },
		{ type: "text", field: "number", protect: true, label: "Number", notes: "Digits only, no spaces or dashes" },
		{ type: "dropdown2", field: "provider", label: "Provider", protect: true, options: providerCodes },
		{ type: "dropdown2", field: "status", label: "Status", protect: true, options: numberStatus },
		{ type: "text", field: "group", protect: true, label: "Group", notes: 'For Number Rotation' },
		
		{ type: "section", name: "Technical Details", cellWidth: 3, open: true },
		{ type: "text", field: "dailyLimit", protect: true, label: "Daily Send Limit" },
		{ type: "text", field: "apiData", protect: true, label: "API Data" },
		{ type: "text", field: "code", protect: true, label: "System Code" },
	
		{ type: "section", name: "Usage Status", cellWidth: 3, open: true },
		{ type: "static", field: "lastUsed", label: "Last Used", width: 3 },
		{ type: "static", field: "today", label: "Last Day Sent", width: 3 },
		{ type: "static", field: "sentToday", label: "Sent Today", width: 3 },
		{ type: "static", field: "errors", label: "Errors", width: 3 },
		{ type: "break" },
		{ type: "json", field: "errorsByType", label: "Account Code", notes: "e999 = Undeliverable, e189 = Spam", width: 12 },
		
		{ type: "section", name: "Notes", cellWidth: 8, open: true },
		{ type: "textarea", field: "notes", label: "Notes", notes: "Notes on this number" },
		
		{ type: "section", name: "Custom Responses", cellWidth: 3, open: true },
		{ type: "textsms", field: "helpResponse", label: "STOP Reply", notes: "Automated Response" },
		{ type: "textsms", field: "stopResponse", label: "HELP Reply", notes: "Automated Response" },
		{ type: "textsms", field: "resumeResponse", label: "RESUME Reply", notes: "Automated Response" },
		
	],

	users: [
	
		{ type: "layout", defaultCellWidth: 3 },

		{ type: "section", cellWidth: 6, noDivider: true },
		{ type: "text", protect: true, field: "name", label: "Name" },
		{ type: "static", field: "email", label: "Email" },

		{ type: "section", name: "Status", open: 1 },
		{ type: "static", field: "lastLogin", label: "Last Login" },
		{ type: "checkbox", field: "approved", label: "Approved" },
		{ type: "checkbox", field: "isAdmin", label: "Admin" },
		{ type: "checkbox", field: "isLido", label: "Lido" },
		{ type: "checkbox", field: "isClient", label: "Client Mode" },
		{ type: "dropdown2", field: "clientCode", label: "Client", options: fSelectClients },

		{ type: "section", name: "Notes", cellWidth: 12 },
		{ type: "notes", field: "notes", label: "Notes", notes: "Notes" },

		{ type: "section", name: "Personalization" },
		{ type: "image", field: "picture", label: "User Icon", width: 12 },

		{ type: "section", name: "Authorization" },
		{ type: "static", field: "issuer", label: "Authentication Provider" },
		{ type: "static", field: "token", label: "Token", width: 8 },
		
	],
	
	accounts : [
	
		// Primary info
	
		{ type: "layout", defaultCellWidth: 4 },
		
		{ type: "section", noDivider: true },
		{ type: "static", field: "code", label: "Client Code", width: 2 },
		{ type: "text", protect: true, field: "apiKey", label: "Api Key" },
		{ type: "text", protect: true, field: "name", label: "Client Name" },
		{ type: "textarea", field: "notes", protect: true, label: "Notes", width: 12 },

		// Short code		
		{ type: "section", name: 'Service Provider', open: true, width: 4 },
		{ type: "dropdown2", protect: true, field: "provider", label: "Provider", options: providers, notes: "Default is Cdyne" },
		{ type: "dropdown2", protect: true, field: "shortCode", label: "Short Code", options: shortCodes, notes: "Default is Cdyne 857-60" },
		{ type: "dropdown2", protect: true, field: "sendGroup", label: "Rotation Group", notes: "(Deprecated)", options: groups },
		
		// Custom replies
		{ type: "section", name: 'Custom Replies', open: true, width: 4 },
		{ type: "textsms", protect: true, field: "replyStop", label: "Stop Reply", width: 4 },
		{ type: "textsms", protect: true, field: "replyHelp", label: "Help Reply", width: 4 },
		{ type: "textsms", protect: true, field: "replyStart", label: "Start Reply", width: 4 },
		
		// Credits & Accoutning
		{ type: "section", name: 'Credits', open: true },
		{ type: "break" },
		{ type: "text", protect: true, field: "credits", label: "Credits", notes: "Available", width: 2 },
		{ type: "static", field: "usage", label: "Usage", notes: "Total Messages Sent" },
		
		// HTTP Notifications
		{ type: "section", name: 'HTTP Notifications', open: true },
		{ type: "text", protect: true, field: "notifyMethod", label: "Notify Method", width: 2 },
		{ type: "text", protect: true, field: "notifyUrl", label: "Notify URL", width: 12 },
		{ type: "text", protect: true, field: "notifyDlrUrl", label: "DLR Notify URL", width: 12, notes: "Optional" },
		{ type: "text", protect: true, field: "notifyPhone", label: "Phone Field Name", width: 2, notes: "Optional" },
		{ type: "text", protect: true, field: "notifyText", label: "Message Text Field Name", width: 2, notes: "Optional" },
		{ type: "text", protect: true, field: "notifyStop", label: "Stop Field Name", width: 2, notes: "Optional" },
		{ type: "break" },
		{ type: "text", protect: true, field: "notifyCustom", label: "Custom Params", notes:'As JSON', width: 12 },
		
		// HTTP Notification Errors
		{ type: "section", name: 'Notification Failures', open: true },
		{ type: "static", field: "notifyError", label: "Error Message", notes: 'Response from your server', hint: 'code', width: 12 },
		{ type: "static", field: "notifyErrorDate", label: "Last Error", hint: 'date' },
		{ type: "static", field: "notifyErrorCount", label: "Consecutive Notification Errors" },
		{ type: "break" },
		
		// Email notifications
		{ type: "section", name: 'Email Notifications' },
		{ type: "text", protect: true, field: "notifyEmail", label: "Notification Email - Non-removes only" },
		
		{ type: "section", name: 'API Enhanced Security' },
		{ type: "textarea", protect: true, field: "allowedIp", width: 10, label: "Allowed IP", notes: "Comma separated, leave blank to allow all", width: 12 },
		
	],

}