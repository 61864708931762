"use strict";

// Npm modules
import {observable, computed, action, autorun} from "mobx";

// #######################
// The zEditor state class
// #######################

module.exports = class zEditStore {

	// ### DATA ###
	
	@observable data = null;
	
	@observable sections  = observable.map();
	@observable unProtect = observable.map();
	@observable invoked   = observable.map();
	@observable edits     = observable.map();
	
	links = {};
	
	@observable changed = false;
	@observable doFocus = true;
	
	// ### CONSTRUCTOR ###
	constructor(data) {
		this.data = observable.map(data);
	}
	
}
