import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
 
@inject('routing')
@observer
export default class App extends Component {
  render() {
    const { location, push, goBack } = this.props.routing;
 
    return (
      <div>
        <div>An error has occurred. This is not a valid URL.</div>
        <button onClick={() => push('/app/sys/dashboard')}>Start Over</button>
      </div>
    );
  }
}