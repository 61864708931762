// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const csv = require('csv-stringify/lib/sync');
const moment = require('moment');
const num = require('numeral'); // http://numeraljs.com/
num.zeroFormat('');

// Makes a report

export default class CsvReport extends React.Component {

    makeCsv = () => {
		
        const stats = this.props.stats;
        const layout = this.props.layout;
        const range = this.props.range;
        const name = this.props.name;

        let headers = [''];

        var rangeArray = [];
        for (var col in range) {
            rangeArray.push(col);
            headers.push(range[col]);
        }

        let rows = [headers];
        for (var r in layout) {
            let columns = [];

            let myLayout = layout[r];

            if (myLayout.type === 'subhead') {
                columns.push(myLayout.name);
                for (let i = 1; i < headers.length; i++) {
                    columns.push('');
                }
                rows.push(columns);
            }
            else if (myLayout.type === 'headers') {
                // rows.push(headers);
                continue;
            }
            else {
                columns.push(myLayout.name);
                rangeArray.forEach(col => {
                    let data = stats[r] || {};
                    columns.push(num(data[col]).format('0'));
                });
                rows.push(columns);
            }
        }

        let csvContent = "data:text/csv;charset=utf-8," + csv(rows);
        let encodedUri = encodeURI(csvContent);

        let stamp = moment().format(`YYYY-MM-DD`);
        let fileName = `${name}_${stamp}.csv`;

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click(); 

	}

    render() {

        return (
            <a className='btn btn-sm btn-primary app-link-affordance' onClick={this.makeCsv} >
                <i className="fa fa-arrow-down" aria-hidden="true"></i>&nbsp;
                <i className="fa fa-file-text-o" aria-hidden="true"></i>&nbsp;
                Download CSV
            </a>
        );

    }
}