// NPM modules
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

var moment = require('moment');
var num = require('numeral'); // http://numeraljs.com/
num.zeroFormat('');


var lidoOnly = {
	'lido_42109_AmericanSvcPets': 1,
	'lido_52030_RentandOwn': 1,
	'Lido_97830_RTO': 1,
};

// The list object

@observer
export default class Picker extends React.Component {

  render() {
  
  	// Use the app state (store)
  	var store = this.props.store;
  	if (store.loading) return false;
  	if (! store.modeData) return false;
 		var uiConfig = this.props.config;
 		var data = store.modeData;
 		
 		
 		var lido = false;
 		if (store.authData.isLido && (! store.authData.admin)) lido = true;
 		
		// Rows
		var rows = [];
		for (var client in data) {
			if (lido && (! lidoOnly[data[client].name])) continue;
			rows.push(<Row key={client} client={client} store={this.props.store} />);
		}
		
    return (
			<div className="container-fluid">
		
				<Header />

				<table className='table table-striped table-hover table-condensed app-picker'>
					<tbody>
					<tr>
					
						<th>Client</th>
						
						<th>Credits</th>
						
						<th>Queued<br />(Today)</th>
						<th>Sent<br />(Today)</th>
						<th>Removes<br />(Today)</th>
						
						<th>Queued<br />(7 Days)</th>
						<th>Sent<br />(7 Days)</th>
						<th>Removes<br />(7 Days)</th>
								
					</tr>
					{rows}
					</tbody>
				</table>
			
			</div>
    );
  }
  
}


// ### HEADER ###

@observer
class Header extends React.Component {

	render() {
		
		var store = this.props.store;
		
		return (
			<div className="zero-detail-control-wrapper">
				<div className="row zero-detail-controls">
					<div className="col-sm-12 zero-detail-text">
						<h4>Select a client account to inspect</h4>
					</div>
				</div>
			</div>
		);
	}

}

// ### ROW ###

@observer
class Row extends React.Component {

	clickMe = () => {
		var conf = this.props.conf;		
		this.props.store.navClient(this.props.client, 'dashboard');
	}

	render() {
		
		var store = this.props.store;
		var client = this.props.client;
		var info = store.modeData[client];
		
    return (
    	<tr>
    		<td>
    			<a onClick={this.clickMe}><i className="fa fa-address-card-o" aria-hidden="true"></i></a>
    			 &nbsp;
    			<a onClick={this.clickMe}><b>{info.name}</b></a>
    		</td>
    		<td className="app-report-num">{num(info.credits).format('0,0')}</td>
    		
    		<td>{num(info.stats.saved.d0).format('0,0')}</td>
    		<td>{num(info.stats.queued.d0).format('0,0')}</td>
    		<td>{num(info.stats.stop.d0).format('0,0')}</td>

    		<td>{num(info.stats.saved.d7).format('0,0')}</td>
    		<td>{num(info.stats.queued.d7).format('0,0')}</td>
    		<td>{num(info.stats.stop.d7).format('0,0')}</td>

    	</tr>
    );
    
	}

}
